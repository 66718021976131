.quickorder-page {
  font-size: $font-size-regular;
  color: $df-black;

  .quickorder-header {
    text-align: center;
  }

  .quick-qa {
    display: flex;
    flex-direction: column;

    font-size: $font-size-regular;
  }

  .quick-direction {
    font-size: $font-size-regular;
    color: $df-black;
    margin-top: 0.5rem;
  }

  .df-dropdown {
    margin: 20px 0;
  }

  .df-select-container {
    margin: 20px;
  }

  .quickorder__quantities {
    padding: 6px 8px;
    width: 100%;
    display: flex;
    border: 1px solid #c8c8c8;
  }

  .shipping-info {
    font-size: $font-size-regular;
    text-align: center;
    margin: $df-padding-large auto;
    letter-spacing: 0.6px;

    span {
      font-weight: bold;
      font-size: $font-size-regular;
    }
  }

  .df-cta-container {
    margin: 25px auto;
    .df-cta {
      display: block;
      margin: auto;
    }
  }

  .quickorder__quantities {
    color: $df-black;
  }

  .quickorder__quantities::placeholder {
    color: $df-black;
  }
}

//tablet only
@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  .quickorder-page {
    .df-input-container {
      display: flex;
      flex-wrap: wrap;
    }

    .shipping-info {
      margin: 47px 0 20px 0;
    }

    .created-by {
      margin: 0px 0 40px 0;
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .quickorder-page {
    .df-select-container {
      margin: 20px 0;
    }
  }
}

//non-mobile
@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .quickorder-page {
    .quick-qa {
      display: flex;
      flex-direction: row;
      justify-content: center;
      * {
        margin: 2px;
      }
    }

    .df-input-container {
      display: flex;
      justify-content: center;
      align-items: baseline;
      margin-top: 20px;

      .df-select-container {
        width: 33%;
        max-width: 300px;
      }
    }
  }
}



//mobile only
@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  input#quickorder-quantity {
    border: 1px solid #cccccc;
  }
}
