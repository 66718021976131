.add_shipping_address_page {
  min-height: calc(100vh - 200px);
}

@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .add_shipping_address_page {
    .back {
      font-size: $font-size-regular;
      margin-left: 12%;
      margin-top: 2%;
    }

    .center-container {
      width: 60%;
      background-color: #ffffff;
      margin: 5% auto 10px auto;
      .title {
        text-align: center;
        padding-top: 4%;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
        font-size: $font-size-regular;
        letter-spacing: 0.88px;
        line-height: 20px;
        color: $df-black;
        margin-bottom: 40px;
      }
    }

    .input-boxes {
      height: 35%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .row-box {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          .input-field {
            margin-left: 5%;
            margin-right: 5%;
            margin-bottom: 14px;
            width: 70%;
          }
        }
      }
    }

    .df-text-inputs {
      font-size: $font-size-regular;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $df-gray;
      &::-webkit-input-placeholder {
        color: $df-black;
      }
    }

    .mandatory {
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      line-height: 20px;
      margin-left: 5%;
      margin-top: 2%;
      letter-spacing: 0;
      color: $df-red;
    }

    .primary-address {
      display: flex;
      justify-content: center;
      margin-top: 5%;
    }
    .checkbox-class {
      float: left;
      margin-right: 1%;
    }
    .check-text {
      float: left;
      color: #000000;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      line-height: 20px;
    }

    .button-class {
      display: flex;
      justify-content: center;
      .df-cta {
        padding: 5px;
      }
    }

    .button {
      width: 250px;
      margin: 5% 0;
      letter-spacing: 0.88px;
    }

    .df-link {
      display: flex;
      align-items: center;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-regular;
      color: $df-red !important;
      width: fit-content;
      &:hover {
        text-decoration: none;
        color: $df-red;
      }
    }
  }
}

@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  .add_shipping_address_page {
    .back {
      font-size: $font-size-regular;
      margin-left: 15px;
      margin-top: 22px;
    }

    .center-container {
      background-color: #ffffff;
      margin: 60px 50px;
      .title {
        text-align: center;
        padding-top: 30px;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
        font-weight: 300;
        font-size: $font-size-regular;
        letter-spacing: 0.88px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 7%;
      }
    }

    .input-boxes {
      height: 35%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .row-box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .input-field {
            width: 70%;
            margin: 0 24px;
            margin-bottom: 14px;
          }
        }
      }
    }

    .df-text-inputs {
      font-size: $font-size-regular;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $df-gray;
      &::-webkit-input-placeholder {
        color: $df-black;
      }
    }

    .mandatory {
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      line-height: 20px;
      letter-spacing: 0;
      color: $df-red;
      margin-left: 24px;
    }

    .primary-address {
      display: flex;
      justify-content: center;
      margin-top: 5%;
    }
    .checkbox-class {
      float: left;
      margin-right: 1%;
    }
    .check-text {
      float: left;
      color: #000000;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      line-height: 20px;
    }

    .button-class {
      display: flex;
      justify-content: center;
    }

    .button {
      width: 250px;
      margin: 40px 0;
      letter-spacing: 0.88px;
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .add_shipping_address_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    width: 100%;

    .back {
      font-size: $font-size-regular;
      margin-left: 16px;
      margin-top: 2%;
    }

    .center-container {
      background-color: #ffffff;
      margin: 10px 16px;
      padding: 24px 24px 0;
      .title {
        text-align: center;
        padding-top: 6px;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
        font-weight: 300;
        font-size: $font-size-regular;
        letter-spacing: 0.88px;
        line-height: 20px;
        color: #000000;
        margin-bottom: $df-padding-large;
      }
    }

    .input-boxes {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .row-box {
          display: flex;
          flex-direction: column;

          .input-field {
            margin-bottom: 14px;
            width: 100%;
          }
        }
      }
    }

    .df-text-inputs {
      font-size: $font-size-regular;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $df-gray;
      &::-webkit-input-placeholder {
        color: $df-black;
      }
    }

    .mandatory {
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      line-height: 20px;
      letter-spacing: 0;
      color: $df-red;
    }

    .primary-address {
      display: flex;
      justify-content: left;
      margin-left: 5%;
      margin-top: 10%;
    }
    .checkbox-class {
      float: left;
      margin-right: 1%;
    }
    .check-text {
      float: left;
      color: #000000;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      line-height: 20px;
    }

    .button-class {
      display: flex;
      justify-content: center;
    }

    .button {
      width: 250px;
      margin: 40px 0;
      letter-spacing: 0.88px;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: $df-red;
  border-color: $df-red;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: $df-red;
  border-color: $df-red;
  color: #fff;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.srv-validation-message {
  color: $df-red;
  font-size: 10px;
  line-height: 1;
  padding: 4px;
  text-transform: capitalize;
}
