.Invoice {
  border-bottom: 1px solid $df-gray-footer;
  .Invoice__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 18px 0;
    cursor: pointer;
  }

  .invoice-date {
    margin-right: 0.5rem;
    color: $df-black;
  }

  .invoice-amount {
    font-family: $font-family-sans-text;
    font-weight: $font-weight-regular;
    margin-top: 3px;
    font-size: 14px;
  }

  .invoice-stats {
    display: flex;
    align-items: center;
  }

  .invoice-status.awaiting {
    display: flex;
    align-items: center;
    color: $df-red;
    margin-left: 1rem;
    .Svg {
      fill: $df-red;
      height: 26px;
      margin-right: 0.5rem;
      margin-left: -4px;
      margin-right: 4px;
    }
  }

  .invoice-status.paid {
    margin-left: 1rem;
    .Svg {
      stroke: red;
      height: 15px;
      border-radius: 100%;
      border: 1px solid black;
      margin-right: 0.5rem;
    }
  }

  .InvoiceList {
    background-color: $df-white;
    padding: 20px;
    .InvoiceList__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    .InvoiceListItem {
      font-size: $font-size-small;
      color: $df-black;

      .listitem__name {
        color: black;
      }
      .listitem__attrs,
      .prices {
        display: flex;
        justify-content: space-between;
      }

      .prices {
        .amount {
          color: black;
        }
      }
    }
  }
}
