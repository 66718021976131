#invoice-details-container {
    padding: 25px 40px 25px;
    position: relative;
}

#invoice-details-container p {
    margin: 0;
    font-size: 15px;
    font-family: "Times Ten Roman", serif;
    line-height: 15px;
}

#invoice-details-container h5 {
    font-family: "Helvetica", serif;
    font-weight: bold;
    font-size: 15px;
}

#invoice-details-container h6 {
    font-family: "Times Ten Roman", serif;
    font-style: italic;
    font-weight: 900;
    font-size: 15px;
}

.invoice-df-logo-styled {
    height: 100%;
    padding-bottom: 20px;
}


@page {
    size: auto;   /* auto is the initial value */
    /* this affects the margin in the printer settings */
    margin: 6.35mm 6.35mm 6.35mm 6.35mm;
}

.invoice-top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: start;
}

.invoice-top__item-1 {
    position: relative;
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.invoice-top__item-2 {
    grid-row: 1 / span 2;
    grid-column: 3 / span 2;
    text-align: left;
}

.invoice-header {
    font-size: 35px;
    font-family: "Times Ten B", serif;
    padding-bottom: 17px;
}

.license {
    margin-bottom: 10px !important;
}

.account-number {
    margin-bottom: 0 !important;
}

.total-info-panel {
    padding: 5px 50px 20px 50px;
    width: 30%;
}

.total-info-panel div {
    display: flex;
    justify-content: space-between;
}

.total-info {
    font-weight: bold;
    margin-right: 5px;
}

.transaction-details-on {
    display: none !important;
}

.invoice-info-row {
    display: flex;
    justify-content: space-between;
    margin: 0;
}

/* Footer */
.invoice-footer {
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    text-align: center;
}

.invoice-footer p {
    font-family: 'Times Ten B', serif;
    font-weight: 900;
}

@media print {
    .invoice-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
    }
}
/* Table */
.invoice-table-row {
    border: 3px solid #ababac;
}

.invoice-table-header {
    background-color: #ababac;
    color: white;
    text-align: left;
    font-size: 15px;
    font-family: 'Helvetica', serif;
    font-weight: bold;
    -webkit-print-color-adjust: exact !important;
}

.invoice-table-header-row {
    border: 3px solid #ababac;
}

.invoice-table-data {
    border: 2px solid #ababac;
    vertical-align: top;
    text-align: left;
    font-family: 'Times Ten Roman', serif;
    font-size: 15px;
    line-height: 15px;
    padding: 5px;
}

.invoice-table {
    margin-top: 20px;
    margin-bottom: 20px;
    border-color: #ababac;
    border-left: 3px solid #ababac;
    border-right: 3px solid #ababac;
    border-bottom: 2px solid #ababac;
    width: 100%;
}
