.index-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //height: calc(100vh - 60px);
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;

  .index-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
  }

  .login-desktop {
    background-color: $df-white;

    .learn-more {
      a {
        margin-left: 3px;
      }
    }

    .contact-us {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0px;
    }
  }

  .Copyright {
    justify-content: center;
    margin-top: 200px;
    color: $df-gray-copyright;
    font-family: $font-family-sans-text;
    font-weight: $font-weight-regular;
    font-size: $font-size-small;
    position: absolute;
    bottom: 30px;
  }

  .login {
    background-color: white;
    width: 100%;
    font-family: $font-family-sans-text;
    font-weight: $font-weight-regular;
    text-align: center;
    .WaitSpinner__container {
      position: absolute;
    }

    .login-headers {
      text-align: center;

      .login-title {
        width: 188px;
      }

      .login-subtitle {
        font-family: $font-family-sans-display;
        font-weight: $font-weight-light;
        color: $df-black;
        font-size: 20px;
        letter-spacing: 1.2px;
        margin-top: 10px;
        @media screen and (min-width: $TABLET_WIDTH_MIN) {
          font-size: 20px;
        }
      }
    }

    form {
      position: relative;
    }

    .error-message {
      text-align: center;
      height: 20px;
    }

    .error-message.auth {
      width: 100%;
      top: 0;
      font-size: 11px;
    }

    .username-input,
    .password-input {
      margin-top: 100px;
      padding-bottom: 5px;
      text-align: center;
      font-size: 16px;
      @media screen and (min-width: $TABLET_WIDTH_MIN) {
        font-size: $font-size-small;
      }
    }

    .error-message.email {
      top: 10px;
      left: 0;
      width: 100%;
      font-size: 10px;
    }

    .username-input {
      margin-top: 20px;
    }

    .error-message.password {
      top: 70px;
      left: 0;
      width: 100%;
      font-size: 10px;
    }

    .password-input {
      margin-top: 20px;
    }

    .sign-in-btn {
      margin: 23px auto;
      width: fit-content;
      padding: 15px 50px;
      font-family: $font-family-sans-display;
      font-weight: $font-weight-bold;
      line-height: 0;
      font-size: 16px;
      @media screen and (min-width: $TABLET_WIDTH_MIN) {
        padding: 0 50px;
        font-size: 13px;
      }
      &:focus {
        box-shadow: none;
      }
    }

    .df-link-container {
      font-size: 16px;
      @media screen and (min-width: $TABLET_WIDTH_MIN) {
        font-size: $font-size-small;
      }
      &.df-link-container--small {
        font-size: $font-size-small;
        @media screen and (min-width: $TABLET_WIDTH_MIN) {
          font-size: 10px;
        }
      }
    }

    .register {
      margin-top: 50px;
    }
  }
}

//non cellphone
@media screen and (min-width: $TABLET_WIDTH_MIN) {
  body {
    .index-page {
      background-color: $df-home-bg;
    }
  }
  .index-page {
    background-color: $df-home-bg;

    justify-content: center;
    align-items: center;
    .login-desktop,
    .login {
      width: 270px;
      height: 340px;
    }

    .index-container {
      flex-flow: row;
      display: flex;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
      width: fit-content;
      margin-bottom: 250px;
    }

    .Copyright {
      justify-content: center;
      margin-top: 200px;
      color: $df-copyright-white;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      position: absolute;
      bottom: 3%;
    }

    .login-desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      .description-container {
        text-align: center;
        font-size: $font-size-small;
        color: $df-black;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
        line-height: 20px;

        div {
          margin: 10px auto;
        }
        .contact-us {
          margin-top: 20px;
        }
        .contact-us:not(:root:root) {
          //Only for Safari
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-top: 30px;
        }

        .df-title {
          color: black;
          font-size: $font-size-small;
          font-family: $font-family-sans-display;
          font-weight: $font-weight-bold;
          letter-spacing: 1px;
          margin: 0px 5px 20px;
        }
      }
    }

    .login {
      padding: 0 33px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .login-headers {
        margin-top: 24px;
      }
    }
  }
}

//tablet only
@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  .index-page {
    background: url('../static/images/bg-login-new.jpg') no-repeat center;
    background-size: cover;
  }
}

//desktop only
@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .index-page {
    background: url('../static/images/bg-login-new.jpg') no-repeat center;
    background-size: cover;
    background-position: bottom;

    .index-container {
      display: flex;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
      width: fit-content;
      margin-bottom: 260px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

//Large screen desktop
@media screen and (min-width: $LARGE_SCREEN_MIN) {
  .index-page {
    background-size: auto;
  }
}
