@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .Diamonds {
    position: relative;
    max-width: $layout-width;
    flex: 1 0 auto;
    margin: 0 auto;
  }
}

.Diamonds {
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  font-size: $font-size-regular;
}
