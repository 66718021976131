.DiamondFilters__header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
    padding: 0;
  }

  .DiamondFilters__header-column {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin: 0 5px;

    .toggle-filter {
      display: flex;
      .filters-label {
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
      }
    }

    .material-icons {
      color: $df-red;
    }

    .df-text-inputs::placeholder {
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
    }

    .Svg {
      position: absolute;
      fill: $df-black;

      right: 0;
      top: 14px;
      height: 25px;
    }
  }
}

.DiamondFilters__dropdown {
  margin-bottom: 1rem;
  @media screen and (min-width: $TABLET_WIDTH_MIN) {
    padding: 0 1rem;
  }

  @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
    padding: 0;
  }
}

.save-filter-icon {
  height: 20px;
  fill: white;
  margin-top: -3px;
}

.FilterButton-container {
  display: flex;
  margin-top: 15px;
}

.FilterButton-container.shape {
  justify-content: center;
  flex-wrap: wrap;
}

.FilterButton-container.color,
.FilterButton-container.clarity,
.FilterButton-container.cut {
  justify-content: space-around;
  margin: 30px 0;
}

.save-filter {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: $df-red;
  cursor: pointer;
  padding: $df-padding-medium;

  button.SaveNotifier {
    margin-right: $df-padding-medium;
  }
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .DiamondFilters__header {
    .DiamondFilters__header-column.DiamondFilters__search {
      width: 200px;
    }
  }
  .gcal-mobile {
    display: none;
  }
  .gcal-desktop {
    display: flex;
    margin-top: 4px;
  }
  label.gcal-txt {
    margin-bottom: 8px;
    margin-left: 15px;
  }
  .gcal-filter {
    display: flex;
    display: none;
  }
}
@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .DiamondFilters__header {
    .DiamondFilters__header-column {
      .Svg {
        height: 22px !important;
      }
      .toggle-filter {
        padding-left: 13px;
      }
    }
  }
  .gcal-desktop {
    display: none;
  }
  .gcal-mobile,
  .gcal-filter {
    display: flex;
    display: none;
  }
  .gcal-filter {
    padding-top: 12px;
  }
  .gcal-mobile {
    padding-bottom: 4px;
  }
  label.gcal-txt {
    padding-left: 16px;
  }
  .gcal-desktop-txt {
    display: none;
  }
}
.gcal-checkbox {
  margin-left: 8px;
  margin-right: 9px;
  height: 14px;
  width: 14px;
}

.gcal-filter {
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  font-size: $font-size-small;
  letter-spacing: 1.2px;
  justify-content: center;
  align-items: flex-end;
}
label.gcal-txt {
  font-size: 11px;
  line-height: 18px;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: $font-size-small;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-checkmark {
  position: absolute;
  top: 1px;
  height: 14px;
  width: 14px;
  margin-left: 6px;
  border: solid 1px #acacac;
  background-color: #ffffff;
}

.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.container .checkbox-checkmark:after {
  left: 4px;
  width: 5px;
  height: 9px;
  border: solid $df-red;
  border-width: 0 2px 2px 0;
  transform: rotate(31deg);
  border-radius: 1px;
  bottom: 2px;
}
