$tabGray: #e4e4e4;

.DiamondFilters {
  .nav-tabs {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid $tabGray;
    .nav-item {
      a {
        padding: 0.5rem 0;
      }
      .nav-link {
        border: 1px solid transparent;
        transition: border 0.1s;
        &:hover {
          border-bottom: 1px solid $df-red;
        }
      }
      .nav-link.active {
        border-bottom: 1px solid $df-red;
      }
    }
  }

  #carat-slider-values,
  #price-slider-values {
    display: flex;
    justify-content: space-between;

    margin: 5px 28px 5px 19px;

    .df-text-inputs {
      width: 100px;
      text-align: center;
      font-size: $font-size-regular;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  #price-slider-values {
    .dolar {
      color: $df-black;
    }
  }
  .tab-content {
    overflow: hidden;
    margin-top: 25px;
  }
}

.DiamondFilter__shape {
  position: relative;
  text-align: center;
  width: 60px;
  margin: 10px;
  font-size: 10px;
  text-transform: uppercase;
  .icon {
    max-width: 35px;
    max-height: 35px;
    margin-bottom: 10px;
  }

  .checkmark {
    position: absolute;
    top: -35px;
    right: 0;
    font-size: 15px;
    color: $df-red;
    align-items: center;
    margin: 2rem 0;
  }
}

.DiamondFilter-color,
.DiamondFilter__clarity {
  font-size: $font-size-regular;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    width: 32px;
    height: 32px;
    text-align: center;
  }
}
.DiamondFilter__clarity {
  div {
    width: 100%;
  }
}

.DiamondFilter-color {
  .active {
    color: $df-red;
  }
}

.DiamondFilter__clarity {
  div {
  }
  .active {
    color: $df-red;
  }
}

#carat-slider,
#price-slider {
  margin: 40px;
  box-shadow: none;
}
.noUi-connect {
  background-color: $df-red;
  height: 2px;
}

.noUi-handle {
  border-radius: 20px !important;
  height: 17px !important;
  width: 17px !important;
  top: -8px !important;
  &:before {
    display: none !important;
  }
  &:after {
    display: none !important;
  }
}

.noUi-target {
  background: transparent;
  border: 0;
  box-shadow: none;
}
.noUi-base {
  .noUi-origin,
  .noUi-handle,
  .noUi-touch-area {
    outline: none;
  }
  .noUi-connects {
    height: 2px !important;
    background: #d3d3d3;
  }
}
.more-link {
  padding-top: 13px;
}
.more-icon {
  fill: $df-red;
}
.show_more_link svg {
  width: 26px;
}
.show_more_link {
  display: flex;
  flex-direction: column;
  font-size: $font-size-regular;
  align-items: center;
  justify-content: center;
}
.savefilter-label {
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.75;
    font-weight: 200;
    font-size: 40px;
  }
}
//desktop filter
.filter_tab {
  display: none;
  &.active {
    display: block;
  }
}
@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .filter-title {
    font-size: 11px;
  }
  #carat-slider,
  #price-slider {
    margin: 40px auto 0;
    text-align: center;
    max-width: 80%;
  }
  .DiamondFilter-color div {
    width: 32px;
    height: 32px;
    margin-right: 4px;
    max-width: 90%;
  }
  .DiamondFilter__cut div {
    min-width: 80px;
  }
  .DiamondFilters #carat-slider-values,
  .DiamondFilters #price-slider-values {
    margin: 5px 0px 5px 0px;
  }
  .DiamondFilters {
    .df-title {
      margin-top: 32px;
    }
  }
}
