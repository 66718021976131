.on-behalf-of-banner {
  margin-right: $df-padding-large;
  border: 1px solid $df-gray;
  font-size: $font-size-small;
  padding: $df-padding-small;
  border-radius: 5px;
  white-space: nowrap;
  
  .on-behalf-of-banner-close {
    margin-left: $df-padding-medium;
  }
}
