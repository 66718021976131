.footer-pages.cart {
  height: 100vh;
}

.cart-page {
  flex: 2;
  position: relative;
  .order-again {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 15px;
    padding-bottom: 13px;

    .tail-message {
      margin-top: 12px;
      color: $df-black;
      font-size: $font-size-small;
    }
  }

  .df-title {
    margin: 3rem 0;
  }

  .cart-list {
    .empty-cart {
      text-align: center;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
    }
  }
  .total-price {
    display: flex;
    justify-content: space-between;
    margin: 25px;

    span {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }

  .terms {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: $font-size-small;

    .terms-text {
      padding-left: $df-padding-medium;
    }

    input {
      margin-right: $df-padding-xsmall;
    }

    label {
      display: flex;
      align-items: baseline;
    }

    a {
      margin-left: $df-padding-xsmall;
      text-decoration: underline;
    }
    .terms-detail {
      padding: 0 10px;
      display: flex;
      align-items: baseline;
    }
    .container {
      justify-content: center;
      width: auto;
      position: absolute;
      padding-right: 214px;
    }
    .checkbox-checkmark {
      left: -19px;
      top: 4px;
    }
    align-items: baseline;
  }

  .df-cta-container {
    text-align: center;
    font-size: $font-size-small;
    margin: $df-padding-large;

    .payment-method-container {
      margin-bottom: $df-padding-small;
    }

    .payment_method {
      font-size: $font-size-small;
      padding-right: $df-padding-small;
    }

    .checkout-error {
      color: $df-red;
      font-size: $font-size-small;
      margin: 10px 0px;

      &.credit-card {
        a {
          text-decoration: underline;
        }
      }
    }
  }

  .cart-page-content {
    display: flex;
    justify-content: center;
  }
}
.checkout-page {
  .df-cta-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .checkout-error {
      color: $df-red;
      font-size: $font-size-small;
    }

    .checkout-button {
      margin: $df-padding-small;
    }
  }
  .dashboard-checkbox {
    display: none;
  }
}

.user-comment {
  resize: none;
  border-radius: 0;
  border: 1px solid #d1d3d4;
}

.label-comment {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  font-size: 11px;
  line-height: 1.82;
  color: $df-black;
}

.label-input {
  display: none !important;
}

.form-control:focus {
  border: 1px solid #d1d3d4;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder,
.form-control {
  color: $df-charcoal-gray;
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  font-size: $font-size-small;
  line-height: 1.33;
}

.form-check {
  display: flex;
}

.detail-column-one {
  margin-right: $df-padding-large;
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .cart-page {
    .total-price {
      margin: 25px auto;
      max-width: 500px;

      .label {
        margin-left: 50px;
      }
    }
  }
  .checkout-page {
    .total-price {
      .label {
        margin-left: 0;
      }
    }
  }
}

@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .cart-page {
    .CartListItem {
      margin: 24px auto;
    }
  }
  .checkout-page {
    .cart-page {
      .CartListItem {
        max-width: 100%;
      }
      .total-price {
        max-width: 100%;
      }
    }
    .shipping-address-component .detail-column-one {
      padding-top: 28px;
    }
  }
  .comment-box {
    max-width: 499px;
    margin: auto;
  }
  .detail-column-two {
    width: 665px;
  }
}
@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .show-nonmobile {
    display: none;
  }
  .comment-box {
    margin: 0 20px;
  }
  .cart-page {
    .cart-page-content {
      flex-direction: column-reverse;
    }
  }
  .detail-column-one {
    margin: 0 13px;
  }
}
@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  textarea#comnttextarea {
    height: 110px;
  }
}
