.register-page,
.forgot-page,
.reset-page {
  display: flex;
  position: relative;
  background-color: white;

  height: 100%;
  overflow: hidden;

  .register-headers,
  .forgot-headers,
  .reset-headers {
    width: 100%;
    text-align: center;
  }
  .Svg.df-logo {
    margin: 30px auto;
  }

  .register-subtitle {
    font-family: $font-family-sans-display;
    font-weight: $font-weight-bold;
    color: $df-black;
    font-size: 16px;
    letter-spacing: 1.2px;
    margin-bottom: 20px;
  }

  .register-instruction {
    font-family: $font-family-sans-text;
    font-weight: $font-weight-regular;
    color: $df-black;
    font-size: $font-size-small;
    color: $df-black;
  }

  .adduser-label {
    color: $df-gray-dark;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .df-cta {
    margin: 10px auto 20px auto;
  }

  .password-icon {
    bottom: 15px;
  }
}

.register-container,
.forgot-container,
.reset-container {
  width: 100%;
}

.registration-form,
.forgot-form,
.reset-form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.registration-form-elements {
  position: relative;
  margin: 15px 0;
}

.instructions {
  font-size: 11px;
}

.error-message {
  margin: $df-padding-small;
  color: $df-red;
  font-size: $font-size-small;
  line-height: 1;
}
.register-page {
  .error-message {
    position: absolute;
    bottom: 42px;
  }
}

.registration-form-elements.password {
  .error-message {
    width: 100%;
  }
}

.adduser-input {
  margin: 0px 0px 15px 0px;
  width: 100%;
}

.account-type-btn {
  width: 100%;
  margin: 15px 0;
}

.circled-number {
  width: 2em;
  height: 2em;
  box-sizing: initial;
  border-radius: 50%;
  line-height: 2em;
  box-sizing: content-box;
  text-align: center;
  font-size: $font-size-small;
  border: 0.1em solid #666;
  
  position: absolute;
  left: -webkit-calc(-2em + -10px);
  left: calc(-2em + -10px);
  top: 5px;
}

.sign-up-terms-container {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 10px;
  font-size: 14px;
}

label {
  font-size: 11px;
  color: $df-gray-dark;
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .register-container,
  .forgot-container,
  .reset-container {
    position: relative;
    width: 568px;
    margin: auto !important;
    background-color: white;

    .sign-in-btn {
      width: 250px;
      margin: 10px auto 40px auto;
    }

    .df-link-container {
      padding: 20px;
    }
  }

  .forgot-form,
  .reset-form {
    margin: 0 $df-padding-large;
  }

  .register-instruction {
    margin-left: 50px;
    margin-right: 50px;
  }

  .registration-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-left: 50px;
    margin-right: 50px;
  }

  .registration-form-elements {
    width: 47.8%;

    &.left {
      margin-right: 10px;
    }
    &.right {
      margin-left: 10px;
    }
  }
}

// tablet only
@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  .register-page,
  .forgot-page,
  .reset-page {
    background: url('../static/images/bg-login-T.png');
    background-size: 100%;
  }

  .circled-number {
    margin-right: 10px;
    position: relative;
    left: 0;
    top: 5px;
  }

  .registration-form-elements {
    &.with-circle {
      width: 39%;
    }
  }
}

// mobile
@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .circled-number {
    margin: 10px;
    position: relative;
    left: 0;
    top: 5px;
  }

  .registration-form-elements {
    &.with-circle {
      width: 100%;
    }
  }
}

@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .register-page,
  .forgot-page,
  .reset-page {
    background: url('../static/images/bg-login-Dsk.png');
  }

  .register-container,
  .forgot-container,
  .reset-container {
    width: 640px;
    max-width: 640px;
    margin: auto !important;
  }

  .registration-form-elements {
    width: 48.8;
  }
}
