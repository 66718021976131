$diamond-table-min-width: 1000px;

.DiamondList {
  .Diamond_selectors {
    display: flex;
    justify-content: space-between;
    margin: $df-padding-small;
    height: 50px;
    align-items: center;
  }

  .df-title {
    font-size: 16px;
  }

  .Svg {
    height: 25px;
    fill: $df-red;
  }

  .table {
    &.detailed {
      // The detailed view will only scale so far. After that point, just let users scroll over.
      min-width: $diamond-table-min-width;

      .thead {
        .tr {
          .th {
            width: 5%;
            &.detailed-only {
              display: block;
            }
            &.detailed-hide {
              display: none !important;
            }
          }
        }
      }

      .tbody {
        .DiamondListItem {
          .tr {
            .td {
              &.detailed-or-mobile,
              &.detailed-only {
                display: block;
              }
              &.detailed-hide {
                display: none !important;
              }        
            }
          }
          .DiamondListItem__dropdown {
            @media screen and (max-width: $diamond-table-min-width) {
              width: 100vw;

              .close__container {
                right: 20px;
              }
            }            
          }
        }
      }
    }

    .thead {
      background-color: $df-light-gray;
      .tr {
        display: flex;
        justify-content: space-between;
        .th {
          cursor: pointer;
          position: relative;
          height: 50px;
          line-height: 50px;
          text-align: center;
          color: #000;
          font-size: 11px;
          text-transform: uppercase;
          flex: 1 0 auto;
          width: 12%;

          @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
            letter-spacing: 0.075em;
          }

          &.detailed-only {
            display: none;
          }

          &.checkbox-header {
            max-width: 20px;
          }

          // Hide the shape icon on tablet and mobile.
          &.shape-icon-header {
            @media screen and (max-width: $TABLET_WIDTH_MAX) {
              display: none;
            }
          }
          .shape__container {
            width: 100%;
            text-align: center;

            .name {
              display: inline;
            }
          }
        }
      }
    }
    .table__filter-icon {
      position: absolute;
      bottom: -25%;
      left: 0;
      right: 0;

      .Svg {
        fill: $df-black;
        height: 20px;
        margin-bottom: 5px;
      }
    }

    .tbody {
      background-color: #fff;

      .DiamondListItem {
        & > .tr {
          cursor: pointer;
        }
        &:nth-child(even) > {
          .tr {
            background-color: $df-light-gray;
          }
        }
      }
      .tr {
        display: flex;
        justify-content: space-between;
        &:hover {
          background-color: #ededee;
        }

        .td {
          width: 100%;
          max-width: -webkit-fill-available;
          max-width: -moz-available;
          max-width: stretch;
          height: 50px;
          line-height: 50px;
          padding: 0;
          text-align: center;
          color: #000;
          font-size: $font-size-small;
          text-transform: capitalize;
          white-space: nowrap;

          &.detailed-only {
            display: none;
          }
          @media screen and (min-width: $TABLET_WIDTH_MIN) {
            &.detailed-or-mobile {
              display: none;
            }
          }

          &.diamond-shape {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: $TABLET_WIDTH_MAX) {
              display: none; // hide the diamond shape image when on tablet or mobile
            }
          }

          &.checkbox-cell {
            max-width: 20px;
            min-width: 20px;
            padding: 20px 0 0 5px;
            text-align: left;

            .form-check-input {
              margin: 0 auto;
            }
          }
        }

        .diamond-shape {
          .image-wrapper {
            width: inherit;
            margin: auto;
            i {
              display: none;
            }
          }
        }
        &.selected {
          color: $df-red;
          .td {
            &.diamond-shape {
              .image-wrapper {
                position: relative;
                i {
                  display: block;
                  font-size: $font-size-small;
                  background: $df-red;
                  color: white;
                  border-radius: 50%;
                  padding: 1px;
                  position: absolute;
                  top: 8px;
                  right: -20%;
                  border: 1px solid;
                  @media screen and (min-width: $TABLET_WIDTH_MIN) {
                    right: -25%;
                  }
                  @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
                    right: -55%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .DiamondListItem__dropdown {
      background-color: white;
      padding: 20px;
      position: relative;
      display: flex;

      .close__container {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 10px;
      }

      .Svg.close {
        float: none;
        font-size: 20px;
        fill: $df-black;
        &:hover {
          fill: $df-red;
        }
      }

      .DiamondListItem__column {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .diamond-header {
          font-family: $font-family-sans-display;
          font-weight: $font-weight-light;
          font-size: 21px;
        }

        .diamond-id {
          color: $df-black;
          text-align: center;
        }

        .diamond-header.price {
          font-size: $font-size-small;
          font-family: $font-family-sans-text;
          font-weight: $font-weight-regular;
          @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
            font-size: 1.5rem;
          }
        }

        .diamond__img {
          max-height: 275px;
        }

        .diamond-dynamic-info {
          width: 100%;
          text-align: center;
        }

        #vision360 {
          width: 275px;
          margin: 47px 0;
          height: 272px;
          border: none;
        }

        .DiamondListItem__ctas {
          display: flex;
          justify-content: center;
          margin: 1rem 0;
          .DiamondListItem__cta {
            cursor: pointer;
            padding: 10px;
            text-align: center;
            @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
              padding: 10px 20px;
            }

            .cta-label {
              font-size: 0.8em;
              color: black;
              margin-top: 10px;
            }
          }

          img {
            height: 30px;
          }
        }

        .cart-cta__container {
          width: 100%;
          text-align: center;
        }

        .guarantee {
          margin-top: 20px;
          .red {
            color: $df-red;
          }
        }

        .info.more-info {
          margin: 0 auto;
          width: 90%;

          .tr {
            padding: 0 20px;
          }
          .tr .td {
            height: 40px;
            line-height: 40px;
            width: 40%;
            font-size: 13px;
            text-transform: capitalize;
            text-align: justify;

            @media screen and (max-width: $MOBILE_WIDTH_MAX) {
              height: 30px;
              line-height: 30px;
            }
          }
          .tr.clearfix:nth-child(even) {
            background-color: $df-gray-medium;
          }
        }

        .headerinfo {
          font-family: $font-family-sans-display;
          font-weight: $font-weight-light;
          text-align: center;
          padding: $df-padding-medium 0 0 0;
          font-size: 20px;
          color: $df-black;
        }
      }

      .DiamondListItem__column.detail {
        margin-top: 20px;
        .info {
          width: 100%;
          &.infocolor {
            background: $df-gray-medium;
          }
          .tr {
            justify-content: center;
          }
          .td {
            font-size: $font-size-small;
            text-transform: capitalize;
            white-space: normal;
            line-height: 1.7;
            text-align: left;
            padding: $df-padding-small;
            height: auto;
            border: 0;
            &:nth-child(1) {
              width: 20%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 60%;
            }
          }
        }
      }
    }
  }

  .DiamondList__load-more {
    margin-bottom: 0.5rem;
  }
  .DiamondListItem__expanded {
    background-color: #ededee !important;
  }

  .add-to-cart__container {
    margin: 0.5rem 0.5rem 2rem 0.5rem;
  }

  .DiamondList__selector-container {
    display: flex;
    font-size: $font-size-small;
  }

  .DiamondList__selectors {
    cursor: pointer;
    display: flex;
    color: $df-black;
    &:hover {
      color: $df-red;
    }
    .material-icons {
      font-size: $font-size-regular;
      line-height: 1.4;
    }
    .material-icons.check {
      padding-right: 6px;
    }
    .material-icons.clear {
      padding-right: 9px;
    }
  }

  .DiamondList__selectors.allselect {
    margin-left: $df-padding-medium;
  }
  .DiamondList__selectors.clearall {
    margin-left: $df-padding-medium;
  }
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    .DiamondList__selectors.allselect {
      margin-left: $df-padding-small;
    }
    .DiamondList__selectors.clearall {
      margin-left: $df-padding-small;
    }
  }

  .diamond-shape {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 30px;
    }
  }
}
.no-diamond-data {
  text-align: center;
  max-width: 80%;
  margin: $df-padding-large auto;
  font-size: 16px;
  font-weight: 300;
}

.no-diamond-data .notify-text {
  font-weight: 300;
  color: #000000;
  margin-top: $df-padding-large;
}

.no-diamond-data .notify-button {
  display: inline-block;
  margin-top: $df-padding-large;
}

.df__caption {
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  font-style: italic;
  font-size: $font-size-small;
  margin: 15px auto;
}

@media screen and (max-width: $TABLET_WIDTH_MAX) {
  .DiamondList
    .table
    .DiamondListItem__dropdown
    .DiamondListItem__column {
    color: $df-black;
  }
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .DiamondList {
    .table {
      .DiamondListItem__dropdown {
        .DiamondListItem__column {
          text-align: center;
          width: 50%;

          .price {
            font-size: 21px;
          }

          .DiamondListItem__ctas {
            display: flex;
            justify-content: center;
          }

          .cart-cta__container {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .DiamondList {
    .table {
      .DiamondListItem__dropdown {
        display: block;
        .DiamondListItem__column {
          .info.more-info {
            padding-top: $df-padding-small;
            margin: 0 auto;
            width: 90%;
            background-color: #fff;
          }
          #vision360 {
            width: 300px;
            height: 298px;
            border: none;
          }
          .diamond__img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .DiamondList {
    .table {
      .DiamondListItem__dropdown {
        .DiamondListItem__column {
          align-items: inherit;
          .info.more-info {
            .tr .td {
              width: 90% !important;
              font-size: 13px;
            }
          }
          .diamond-header {
            font-size: 21px;
            text-align: center;
          }
        }
      }
      .thead {
        .tr {
          .th {
            font-size: $font-size-xx-small;
            padding: 0;
          }
        }
      }
    }
  }
}

.DiamondList .table .tbody .info .tr:hover {
  background-color: initial;
}

@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  #vision360 {
    width: 425px !important;
    height: 421px !important;
    border: none;
  }
}

@media screen and (max-width: 320px) {
  .DiamondList
    .table
    .DiamondListItem__dropdown
    .DiamondListItem__column
    .DiamondListItem__ctas {
    .DiamondListItem__cta {
      padding: 10px 16px;
    }
  }
}

.button-cta {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.diamond-info__hand,
.diamond-info__image {
  width: 100%;
  max-width: 300px;
  height: 300px;

  @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
    max-width: 425px;
    height: 425px;
  }
}
.diamond-info__photo {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
    max-width: 425px;
    height: 425px;
  }
}
/* HAND PREVIEW */

.diamond-info__image,
.diamond-info__hand {
  margin: 0 auto;
  text-align: center;
  font-size: 0;
}
.diamond-info__hand-wrp {
  height: 100%;
  max-width: 425px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
}
.diamond-info__hand .hand {
  transform: scale(1.9) translateX(-1%) translateY(-18%);
  position: relative;
  width: 100%;
  top: 50%;
}
.diamond-info__hand .diam {
  transform: translate(-50%);
  position: absolute;
  top: 64%;
  left: 33%;
  -ms-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}
.diamond-info__hand .diam[alt='Emerald'] {
  transform: rotate(90deg) translateY(50%);
}
.diamond-info__hand-container {
  overflow: hidden;
  display: block;
  height: 100%;
}

.vision360-wrp {
  position: relative;
  overflow: hidden;
  margin: auto;
  #WaitSpinner {
    -khtml-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    top: 50%;
    position: absolute;
    left: 50%;
    margin-left: -26px;
  }
}
#diamond-video-360 {
  width: 100%;
  max-width: 100%;
  margin: auto;
  @media screen and (min-width: $TABLET_WIDTH_MIN) {
    max-width: 425px;
  }
}

.DiamondListItem__cta.active {
  .cta-label {
    color: $df-red !important;
  }
}
