#WaitSpinner {
  margin: 30px 0 50px;

  #spinner {
    margin: auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border: 0.3em solid rgba(0, 0, 0, 0.25);
    border-left-color: $df-red;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s linear infinite;
    animation: load8 1.1s linear infinite;

    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  #WaitSpinner {
    min-height: 50px;
    position: relative;
    width: 100vw; // Need to scale based on screen width because the diamonds table may go off the side of the page when in detailed mode
  
    #spinner {
      position: absolute;
      left: 50vw;
    }
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
