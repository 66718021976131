.Confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 62px;

  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  padding: 0 20px;

  .close__container {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    width: 100%;
    .close-confirmation {
      height: 27px;
    }
  }

  .checkmark {
    fill: $df-red;
    align-items: center;
    height: 82px;
  }

  .df-title {
    margin: 2rem 0;
    font-size: 16px;
  }

  .df-subtitles {
    .df-subtitle {
      font-size: $font-size-regular;
      margin-bottom: 1rem;
    }
  }
  .df-subtitle {
    font-size: $font-size-regular;
    margin-bottom: 1rem;
  }
  .df-link {
    font-size: 14px;
    margin: 2rem 0;
    justify-content: center;
  }
}

.checkout-page {
  .Confirmation {
    position: static;
    height: unset;

    .close__container {
      .Svg {
        display: none;
      }
    }
  }
}

.modal .modal-credit-limit {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    margin: $df-padding-medium;
  }
  .modal-content {
    padding: $df-padding-large;
    @media screen and (max-width: $MOBILE_WIDTH_MAX) {
      padding: $df-padding-medium;
    }
    .modal-header {
      margin-top: 0;
      padding: 0;
    }
    .modal-body {
      margin: 0;
      padding: 0;
    }
    .modal-title {
      display: block;
      text-align: left;
      font-size: $font-size-large;
    }
    .modal-body {
      display: block;
    }
    .modal-close {
      position: absolute;
      display: block;
      cursor: pointer;
      height: 22px;
      width: 22px;
      opacity: 0.3;
      right: 4px;
      top: 4px;
      &:hover,
      &:focus {
        opacity: 1;
      }
      &:after,
      &:before {
        position: absolute;
        left: 10px;
        top: 0;
        content: '';
        height: 22px;
        width: 2px;
        background-color: #333;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    } 
    .blurb {
      font-size: $font-size-regular;
      margin-top: $df-padding-medium;
      display: block;
    }
    .cta {
      display: flex;
      justify-content: space-between;
      padding-top: $df-padding-small;
      margin-top: $df-padding-medium;  
      @media screen and (max-width: $MOBILE_WIDTH_MAX) {
        flex-direction: column;
      }    
    }
    button {
      @media screen and (max-width: $MOBILE_WIDTH_MAX) {
        width: 100%;
      }
      &:last-child {
        @media screen and (max-width: $MOBILE_WIDTH_MAX) {
          margin-top: $df-padding-medium;
        }
      }
    }
  }
}