// This is the list of groups.
.DiamondList.RoughDiamondGroupList {
  .table {
    .DiamondListItem__dropdown {
      .DiamondListItem__column {
        width: 100%;
      }
    }
  }
}

// This is the list of rough diamonds that is shown when a group is expanded.
.DiamondList.RoughDiamondList {
  width: 100%;
}

// Styles for mobile only
@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .RoughDiamondGroupFilterGrid.DiamondFilterGrid {
    .FilterColorCarat {
      // Override the Bootstrap style to support a 7-column layout
      .col-sm-1 {
        flex: 0 0 11%;
        max-width: 11%;
      }
    }
  }
}
