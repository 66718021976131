
.user-details-page {
  .table-wrapper {
    max-width: 600px;
    margin: 0 auto;

    h1 {
      padding: 0;
      margin: 10px 0;
    }
  }
}