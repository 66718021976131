.resource-center {
  margin: 20px;
  text-align: center;
  .main-title {
    font-family: $font-family-sans-display;
    font-weight: $font-weight-light;
    font-size: $font-size-regular;
    color: #000000;
    padding: 20px;
  }

  .description {
    color: $df-black;
    padding-bottom: 40px;
    font-size: $font-size-regular;
    font-weight: 300;
    line-height: 1.54;
  }
  .about-diamond {
    font-family: $font-family-sans-display;
    font-weight: $font-weight-light;
    font-size: $font-size-regular;
    height: 26px;
    border-bottom: 1px solid #e7e7e7;
    line-height: 1.25;
    color: #000000;
    margin: 0px 50px 30px 50px;
  }
  .video-wrapper {
    display: flex;
    justify-content: center;
  }
}

.download {
  background: #fafafa;
  padding: 20px;
  text-align: center;
  font-family: $font-family-sans-display;
  font-weight: $font-weight-bold;
  color: #000000;
  .head {
    font-family: $font-family-sans-display;
    font-size: $font-size-regular;
    font-weight: $font-weight-light;

    display: block;
    border-bottom: solid 1px #e7e7e7;
    height: 28px;
    margin: 0px 50px;
  }
  .b2bdeck {
    font-family: $font-family-sans-display;
    font-weight: $font-weight-bold;
    font-size: $font-size-regular;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #e7e7e7;
    padding: 16px;
    color: $df-black;
    &:hover {
      color: $df-red;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .b2bdeck.diamond-image {
    border-bottom: solid 1px #e7e7e7;
  }
}

.download-row {
  display: flex;
  text-align: center;
  align-items: center;
  .download-icon {
    position: absolute;
    right: 20px;
    bottom: -142px;
  }
  .name {
    margin-left: 8px;
    text-transform: uppercase;
  }
}

.svg {
  width: 20px;
  height: 20px;
}

.svg.download-file {
  fill: $df-red;
}

//desktop view
@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .resource-cente-desktop-wrapper {
    .resource-center {
      width: 1000px;
      margin: auto;
      .video-wrapper {
      }
      .video {
        width: 600px;
        height: 336px;
      }
    }
  }
  .download {
    width: 900px;
    height: 264px;
    background-color: #fafafa;
    margin: 50px auto;
    .tablet-view {
      display: flex;
      justify-content: center;
      padding: 32px 50px;
      .tablet-view-coloumn {
        width: 324px;
      }
      .b2bdeck {
        &:last-child {
          border-bottom: none;
        }
      }
      .space {
        width: 60px;
      }
      border-bottom: none;
    }
  }
}

//tablet view only
@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  .resource-center {
    .video-wrapper {
    }
    .video {
      width: 600px;
      height: 336px;
    }
  }
  .download {
    .tablet-view {
      display: flex;
      justify-content: center;
      padding: 32px 50px;
      .tablet-view-coloumn {
        width: 324px;
      }
      .b2bdeck {
        &:last-child {
          border-bottom: none;
        }
      }
      .space {
        width: 60px;
      }
      border-bottom: none;
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .resource-center {
    .video-wrapper {
      display: flex;
      justify-content: center;
      iframe {
        width: calc(100vw - 40px);
        height: calc((100vw - 40px) / 1.785);
      }
    }
  }
}
