.invite-user-wrapper {
  padding: 24px 0px;
  .invite-user-content {
    text-align: center;
    background: white;
    width: 280px;
    height: 450px;
    margin: auto;
    padding: 16px;
    .head {
      display: flex;
      justify-content: center;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: 16px;
      color: $df-black;
      padding: 18px 0px;
      text-transform: uppercase;
    }
    .wrapper {
      display: flex;
      justify-content: center;
      margin: 0px 14px;
      flex-direction: column;
      text-align: center;
    }
    .input-wrapper {
      height: 72px;
      input {
        padding: 8px;
        height: 36px;
        border: 1px solid hsl(0, 0%, 80%);
        font-size: 16px;
      }
    }
    .submit-button {
      margin: 32px auto;
      width: 250px;
    }
  }
}

//Tablet view and desktop view
@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .invite-user-wrapper {
    height: inherit;
    padding: 24px;
    .invite-user-content {
      width: 100%;
      .wrapper {
        flex-direction: row;
        display: flex;
        width: 500px;
        margin: auto;
      }
      .input-wrapper {
        width: 50%;
        padding: 16px;
        input {
          margin: 0px;
          height: 36px;
          padding: 8px;
        }
      }
      .drop-down {
        width: 50%;
        padding: 16px;
      }
    }
  }
}
