
.main-wrapper.terms {
  font-size: $font-size-regular;
  
  h1 {
    font-family: $font-family-sans-display;
    font-weight: $font-weight-light;
    text-align: center;
    margin: 25px auto;
  }
  h3 {
    font-family: $font-family-sans-display;
    font-weight: $font-weight-bold;
  }
}
