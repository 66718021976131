/*
  This file contains styles relevant to the sticky footer that appears when you select multiple diamonds
  on the diamonds list page.
*/
$multiselect-foooter-height: 50px;

.multiselect_footer_wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: $multiselect-foooter-height;
  min-height: $multiselect-foooter-height;
  z-index: 100;
  
  .multiselect_footer_body {
    height: $multiselect-foooter-height;
    background: #FFFFFF;
    box-shadow: 0px -1px 5px 0 #cccccc;
    width: 100vw;
    z-index: 101;

    .multiselect_footer_items_wrapper {
      position: relative;
      margin: 0 auto;
      max-width: $layout-width;
      height: $multiselect-foooter-height;

      display: flex;
      align-items: center;
    }

    .multiselect_footer_items {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;

      font-size: 14px;
      text-align: right;
  
      .multiselect_footer_label {
        display: inline-block;
        text-align: left;
        margin-left: 16px;
      }
      .multiselect_footer_value {
        display: inline-block;
        text-align: right;
        font-family: $font-family-sans-display;
        font-weight: 700; // TODO: We do not have the bold TradeGothic font used by Amelie in the design.
        padding-right: 20px; // it's necessary to use padding rather than margin here in order to get the border to appear in the correct place
        margin: auto 0 auto 60px;
        &.border_right {
          border-right: 1px solid #cccccc;
        }
      }
      button {
        display: inline-block;
        height: $multiselect-foooter-height;
        width: auto;
        padding: 0 30px;
      }
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .multiselect_footer_wrapper {
    .multiselect_footer_body {
      .multiselect_footer_items {
        .multiselect_footer_label {
          margin-left: 10px;
        }
        .multiselect_footer_value {
          margin: auto 0 auto 10px;
          &.border_right {
            border-right: none;
          }
        }
        button {
          padding: 0 1rem;
        }
      }
    }
  }
}

