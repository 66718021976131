// Many of these styles are shared with _Register.scss

.df-link-container.reset {
  display: flex;
  justify-content: center;
}

.forgot-page,
.reset-page {
  .forgot-container,
  .reset-container {
    height: 100%;
  }
  .forgot-form,
  .reset-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .reset-form {
    .password-input {
      margin-bottom: 2rem;
    }
    .password-icon {
      bottom: 35px;
    }
  }

  .logo {
    width: 188px;
  }
  .df-logo {
    margin: 30px 0 10rem 0;
  }

  .df-title {
    font-size: 16px;
    margin-bottom: 3rem;
  }

  .adduser-label {
    color: $df-black;
  }

  .df-cta {
    margin: 4rem 0;
  }

  .df-link-container {
    text-align: left;
    width: 100%;
  }
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .forgot-page,
  .reset-page {
    .forgot-container,
    .reset-container {
      height: unset;
    }

    .forgot-container {
      .Confirmation {
        .df-subtitle {
          font-size: $font-size-small;
        }
      }
    }
  }
}
