/*
  This file sets out some basic styles that can be used site-wide.

  Naming Convention: kebab-case (words-separated-with-dashes)
  Base Unit: 5px (if possible, stick to multiples of 5px in order to maintain unity of appearance)
  Default Font Size: 14px. Unfortunately, this is $font-size-small right now. This needs refactoring.
*/
@import 'mixins.scss';

@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'),
    local('.LucidaGrandeUI'), local('Ubuntu Light'), local('Segoe UI Light'),
    local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}
@font-face {
  font-family: 'Helvetica Now Text';
  src: url('../static/fonts/HelveticaNowText-Light.woff2') format('woff2'),
    url('../static/fonts/HelveticaNowText-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display';
  src: url('../static/fonts/HelveticaNowDisplay-Thin.woff2') format('woff2'),
    url('../static/fonts/HelveticaNowDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display';
  src: url('../static/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
    url('../static/fonts/HelveticaNowDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
$font-family-sans-display: 'Helvetica Now Display', 'system';
$font-family-sans-text: 'Helvetica Now Text', 'system';

$font-weight-bold: 700;
$font-weight-regular: 200;
$font-weight-light: 100;

// PADDING / MARGIN

$df-padding-xlarge: 60px;
$df-padding-large: 35px;
$df-padding-medium: 15px;
$df-padding-small: 10px;
$df-padding-xsmall: 5px;

// SIZES

$layout-width: 1200px;

$font-size-base: 10px;
// all font sizes are sized from 10 pixels, and should the same base across devices
$font-size-xx-small: 1rem;
$font-size-x-small: 1.2rem;
$font-size-small: 1.4rem;
$font-size-regular: 1.6rem;
$font-size-medium: 1.8rem;
$font-size-large: 2.1rem;
$font-size-x-large: 2.8rem;
$font-size-xx-large: 3.6rem;

$MOBILE_WIDTH_MAX: 640px;
$TABLET_WIDTH_MIN: $MOBILE_WIDTH_MAX + 1;

$TABLET_WIDTH_MAX: 1279px;
$DESKTOP_WIDTH_MIN: $TABLET_WIDTH_MAX + 1;

$LARGE_SCREEN_MIN: 2000px;

// COLORS

$df-black: #000;

$df-red: #ff3c00;
$df-gray: #cccccc;
$df-gray-dark: #888888;
$df-gray-light: #f6f6f6;
$df-gray-medium: #f1f1f1;
$df-charcoal-gray: #4d4d4f;

$df-gray-nav: #dee2e6;
$df-gray-navlink: #818181;

$df-gray-footer: #e4e4e4;
$df-gray-copyright: #858585;

$df-white: #f8f8f8;
$df-cta-white: #f1f2f2;
$df-cta-gray: #d7d7d7;

$df-copyright-white: #f8f8f8;
$df-home-bg: #010202;

$df-light-gray: #f8f8f8;

//mobile view only
@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .df-show-desktop {
    display: none;
  }

  .df-show-desktop-only {
    display: none !important;
  }

  .df-show-nondesktop-only {
    display: block;
  }

  .df-show-mobile {
    display: block;
  }

  .df-show-nonmobile-only-inline,
  .df-show-nonmobile-only {
    display: none !important;
  }

  .info-toast {
    top: 60px;
  }
}

//tablet view only
@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  .df-show-desktop {
    display: block;
  }

  .df-show-desktop-only {
    display: none !important;
  }

  .df-show-nondesktop-only {
    display: block;
  }

  .df-show-mobile {
    display: none;
  }

  .df-show-nonmobile-only-inline {
    display: inline;
  }

  .df-show-nonmobile-only {
    display: block;
  }

  .df-logout-margin {
    margin: 0 !important;
  }
}

//desktop view
@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .df-show-desktop-only {
    display: block;
  }

  .df-show-nondesktop-only {
    display: none !important;
  }

  .df-show-desktop {
    display: block;
  }

  .df-show-mobile {
    display: none;
  }

  .df-show-nonmobile-only-inline {
    display: inline;
  }

  .df-show-nonmobile-only {
    display: block;
  }

  .df-logout-margin {
    margin: 0 !important;
  }
}

body {
  transition: background-color 0.5s;
}
html,
body {
  font-family: $font-family-sans-text;
  font-size: $font-size-base;

  @media screen and (max-width: $TABLET_WIDTH_MAX) {
    overflow: auto;
  }
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.hidden {
  visibility: hidden;
}

// the main wrapper is required for accessibility - for people who only want to use their keyboard
main {
  position: relative;
}

// use this to wrap the central portion of the page
.main-wrapper {
  max-width: $layout-width;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;

  &.padded {
    padding: 10px;
  }

  h1.title {
    margin: $df-padding-medium;
  }
  
  .subtitle svg {
    max-width: 35px;
    fill: $df-red;
  }

  h3 {
    margin-top: $df-padding-medium;
  }

  p, ul, ol {
    text-align: left;
  }

  // this is a smaller box that defines the part of the page where a user can interact with a form
  .form-wrapper {
    justify-content: center;
    margin: 0 auto;
    padding: $df-padding-medium;
    max-width: 640px;
  }

  // Use this on pages that need a width smaller than $layout-width.
  .inner-wrapper {
    max-width: 640px;
    margin: 0 auto;
    text-align: left;
    .df-headline-container {
      margin-top: 2 * $df-padding-medium;
    }
  }
}

// use this to wrap a custom table
.table-wrapper {
  display: block;
  width: 100%;
  text-align: left;
  font-size: $font-size-regular;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }
  
  // Should this be refactored to use the bootstrap grid? See https://getbootstrap.com/docs/4.0/layout/grid/
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin: $df-padding-xsmall 0;
    min-width: 100px;

    &.left {
      margin-right: $df-padding-small;
    }
    &.right {
      margin-left: $df-padding-small;
    }

    &.inline-block {
      display: inline-block;
    }
  }
}

.page-wrapper-with-padding {
  width: 100%;
  height: initial;
  font-size: $font-size-regular;
  padding: 0 $df-padding-large;
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .page-wrapper-with-padding {
    padding: 0 $df-padding-xsmall $df-padding-xsmall $df-padding-xsmall;
  }
}

.df-logout-margin {
  width: 100%;
  padding: $df-padding-xlarge $df-padding-large $df-padding-large $df-padding-large;
  margin: auto;
  min-height: calc(100vh - 60px);
}

a {
  color: $df-red;
  &:hover {
    color: $df-red;
  }
}

.df-link-container {
  text-align: center;

  a {
    color: $df-red;
  }
}

.df-link {
  color: $df-red !important;
  width: fit-content;

  &:hover {
    text-decoration: none;
    color: $df-red;
  }

  // This is to support old html where df-link needed these styles.
  &.flex {
    display: flex;
    align-items: center;
  }
}

// use this for going to the previous page
.back-link {
  margin-top: $df-padding-small;
}

.WaitSpinner__container {
  background-color: white;
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.disable-scroll {
  overflow-y: hidden;
}

.df-cta {
  color: black;
  background-color: white;
  border-color: $df-red;
  border-radius: 0;
  font-size: $font-size-regular;
  -ms-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  border-width: 1px;
  border-style: solid;
  padding: 0 1rem;
  margin: 0;
  height: 50px;
  cursor: pointer;

  &:hover:not([disabled]) {
    background-color: $df-red;
    border-color: $df-red;
    color: $df-cta-white;
  }

  &:active {
    color: $df-cta-white;
    background-color: $df-red !important;
  }
  > img {
    margin-top: -3px;
    filter: invert(100%);
  }
}

.df-cta3 {
  @extend .df-cta;

  display: flex;
  justify-content: center;
  background-color: $df-cta-gray;
  color: $df-charcoal-gray;
  padding: 8px;
  border-radius: 0;
  border-color: $df-cta-gray;
}

.df-btn-red {
  display: flex;
  justify-content: center;
  background-color: $df-red;
  color: $df-white;
  width: 100%;
  padding: 8px;
  border-radius: 0;

  border-color: $df-red;

  &:hover {
    background-color: $df-red;
    color: $df-white;
    border-color: $df-red;
  }
  > i {
    font-size: 19px;
    margin-right: 0;
    margin-top: -3px;
  }
}
.created-by {
  text-align: center;
  margin: 16px 0 29px 0;
  letter-spacing: 0.6px;
  font-size: $font-size-regular;
  height: 25px;
  line-height: 1;
}

.df-logo {
  letter-spacing: 0.5px;
  font-size: 16px;
  text-align: center;
  font-family: $font-family-sans-display;
  font-weight: $font-weight-bold;
  margin: 0.8rem 0;

  &.left {
    text-align: left;
  }
}

.df-title {
  letter-spacing: 0.5px;
  font-size: 16px;
  text-align: center;
  font-family: $font-family-sans-display;
  font-weight: $font-weight-bold;
  margin: $df-padding-large auto $df-padding-small auto;
  text-transform: uppercase;

  &.left {
    text-align: left;
  }
}

.df-headline {
  font-family: $font-family-sans-display;
  font-weight: $font-weight-light;
  font-size: 2.25rem;
  letter-spacing: -0.003em;
  line-height: 1.25;
}
.df-headline-container {
  text-align: left;
  margin: $df-padding-medium;

  .df-headline {
    margin-bottom: 15px;

    h2 {
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 2.75px;
    }

    span.navigation {
      margin-left: $df-padding-xsmall;
      font-family: $font-family-sans-text;
      font-size: $font-size-regular;
    }
  }
}

.df-small-text {
  font-size: $font-size-x-small;
}

.df-sub-title {
  font-weight: 300;
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  text-align: center;
  font-size: 13px;
  font-style: italic;
}

.Svg.df-logo {
  width: 188px;
}

.df-subtitle {
  text-align: center;
  color: $df-black;
}

.df-text-inputs {
  width: 100%;
  border: 0;
  border-bottom: 1px solid $df-black;
  font-size: $font-size-regular;
  
  &.light {
    border-bottom: 1px solid #e7e7e7;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $df-charcoal-gray;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $df-charcoal-gray;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $df-charcoal-gray;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $df-charcoal-gray;
  }

  &.with-margin {
    margin: $df-padding-small;
  }
}

.PasswordInput {
  position: relative;
  width: 100%;

  .password-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 27px;
    fill: $df-black;
  }
}

.df-dropdown {
  border: 1px solid $df-gray;
  padding: 5px 10px;

  .df-dropdown-toggle {
    display: flex;
    justify-content: space-between;
  }

  .dropdown-menu {
    top: 3px !important;
    left: -10px !important;
    width: 100%;
    border-radius: 0;
    padding: 0;

    .dropdownItem {
      padding: 5px 8px;
    }
  }
}

.df-select__control {
  text-align: left;
  color: $df-black;
  .df-select__placeholder {
    color: $df-black;
  }
}

.df-select_no-padding {
  padding: 0 !important;
}

.df-select__control--menu-is--open {
  border-width: 2px !important;
  box-shadow: none !important;
  border-bottom: none;
}

.df-select__control--is-focused {
  border-bottom: none;
}

.df-select__menu {
  border: 2px solid black;
  border-top: none;
  margin: 0 !important;

  .df-select__menu-list {
    padding: 0 !important;
  }
}

input,
button {
  &:focus {
    outline: 0;
  }
}

.Svg {
  cursor: pointer;
}

.Svg.active {
  fill: $df-red;
}

.input-error {
  &::-webkit-input-placeholder {
    color: $df-red;
  }
}

.input-error-label {
  color: $df-red !important;
}

ul {
  &.unstyled {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &.center {
    text-align: center;
  }
}

a {
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

// This is used by the contact-us modal.
.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  .modal-content {
    position: relative;
    border-radius: 0;
    border: none;
    box-shadow: none;
    min-width: 300px;
    padding: $df-padding-small;
    
    .modal-header {
      margin: 45px 0 0rem;
      border: none;
      border-radius: 0;
      .modal-title {
        font-family: $font-family-sans-display;
        font-weight: $font-weight-bold;
        font-size: $font-size-regular;
        width: 100%;
        text-align: center;
      }
      .close {
        position: absolute;
        right: 15px;
      }
    }
    .modal-body {
      padding: 0 1.5rem;
    }
    .modal-footer {
      padding: $df-padding-large $df-padding-small;
      border: none;
    }
    .df-title {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      .modal-title {
        width: 100%;
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
      }
    }

    .df-page-body {
      margin-bottom: 0;
    }
  }
}

// this is the container used by route switcher
.main {
  min-height: calc(100vh - 200px);
}

.info-toast {
  @include transform(translateX(-50%));
  @include transition();
  bottom: -100px;
  border-radius: 5px;
  position: fixed;
  opacity: 0;
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  border-color: transparent;
  background: #000;
  padding: 20px;
  font-size: $font-size-small;
  text-align: left;
  color: #ffffff;
  left: 50%;
  max-width: 80%;
  width: 400px;
  height: auto;
  &.open {
    opacity: 1;
    bottom: 20px;
    z-index: 1500;
  }
}

.alert {
  font-size: 14px;
}

// Override the hover style on masterial ui components. This disables the halo effect on switches.
.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.svg-icon-csv {
  display: inline-flex;
  align-self: center;
  fill: $df-red;
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

@import '_Index.scss';
@import '_Admin.scss';
@import '_Tables.scss';
@import '_Terms.scss';

// CSS that is specific to a particular component
@import '_Accept.scss';
@import '_AddShippingAddress.scss';
@import '_Cart.scss';
@import '_CartListItem.scss';
@import '_Confirmation.scss';
@import '_ConsignmentTerms.scss';
@import '_DiamondFilters.scss';
@import '_DiamondFilter.scss';
@import '_DiamondFilterGrid.scss';
@import '_DiamondList.scss';
@import '_Footer.scss';
@import '_MinicartListItem.scss';
@import '_MultiselectFooter.scss';
@import '_Nav.scss';
@import '_OnBehalfOfBanner.scss';
@import '_RoughDiamondGroupList.scss';
@import '_ShippingAddress.scss';
@import '_UserList.scss';
@import '_WaitSpinner.scss';

// CSS that is specific to a particular page
@import '_CreditCardPage.scss';
@import '_CustomerAccountsPage.scss';
@import '_Dashboard.scss';
@import '_Diamonds.scss';
@import '_EditUser.scss';
@import 'page/_FooterPages.scss';
@import '_InviteUser';
@import '_NotifyUsersPage.scss';
@import '_OrderDetailsPage.scss';
@import '_OrderListItem.scss';
@import '_Quickorder.scss';
@import '_Recover.scss';
@import '_OrderCheckoutPage.scss';
@import '_OrdersPage.scss';
@import '_Register.scss';
@import '_ResourceCenter.scss';
@import '_UserDetails.scss';
@import '_CheckoutPage.scss';
