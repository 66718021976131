// Styles for the DiamondFilterGrid component and child components
.RoughDiamondGroupFilterGrid,
.DiamondFilterGrid {
  .FilterColor {
    display: flex;
    justify-content: left;
    margin: $df-padding-small 0;
    padding: 0;

    .legend {
      font-size: $font-size-small;
      font-family: 'Helvetica Now Display';
      font-weight: $font-weight-bold;
      max-width: 94px;
      width: 94px;
      padding: $df-padding-xsmall $df-padding-small;  
    }

    .DiamondFilter-color {
      font-size: $font-size-regular;
      margin: 0 $df-padding-small 0 0;

      .active {
        background-color: $df-red;
        color: white;
      }
    }
  }

  // We need to override some of the Bootstrap styles
  .row {
    margin: 0;
  }

  // Styles for the grid
  .FilterColorCarat,
  .FilterShapeCarat {
    // Override the Bootstrap styles to preserve these styles on mobile
    .col-sm-1 {
      flex: 0 5 10%;
      max-width: 10%;
      padding: 0;
    }

    // header for each column
    .header {
      position: relative;
      font-size: $font-size-small;
      font-family: 'Helvetica Now Display';
      font-weight: $font-weight-bold;
      text-align: center;
      padding: $df-padding-xsmall;

      &:hover {
        background-color: $df-gray-light;
      }
    }

    // legend at the side of each row
    .legend {
      font-size: $font-size-small;
      padding: 2px $df-padding-medium;
      font-family: 'Helvetica Now Display';
      font-weight: $font-weight-bold;

      &:hover {
        background-color: $df-gray-light;
      }
    }
  }

  // Styles for the buttons on the grid
  .DiamondGridButton-container {
    text-align: center;

    a.DiamondGridButton {
      font-size: $font-size-small;
      display: block;
      padding: 2px;
      border: 1px solid black;
      margin: -1px 0 0 -1px; // To make the borders overlap.
      background-color: white; // This needs to be white so it overlaps the row header text on small screens.
      color: $df-charcoal-gray;
      &.selected {
        background-color: $df-red;
        color: $df-white;

        &:hover {
          background-color: $df-red;
        }
      }

      &:hover {
        background-color: $df-gray-light;
      }
    }
  }

  // tablet view and below
  @media screen and (max-width: $TABLET_WIDTH_MAX) {
    .FilterColor {
      .legend {
        max-width: calc(8.3333vw - 6px);
        width: calc(8.3333vw - 6px);
        padding: 2px $df-padding-small;  
      }
    }
  }

  // mobile view only
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    // Styles for the buttons on the grid
    .DiamondGridButton-container {
      a.DiamondGridButton {
        padding: 2px 0;
      }
    }

    // Styles for the grid
    .FilterColorCarat,
    .FilterShapeCarat {
      .legend {
        padding: 2px $df-padding-xsmall;
      }
    }

    .FilterColor {
      // Styles for the color buttons
      .DiamondFilter-color {
        margin: 0 $df-padding-xsmall 0 0;
      }
      .legend {
        padding: $df-padding-xsmall 0;
      }
    }
  }
}
