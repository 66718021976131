.shipping-address-component { 
  padding: $df-padding-small;

  .shipping-address {
    border-bottom: solid 2px #e7e7e7;
    font-size: $font-size-small;
    
    &.is-scroll {
      max-height: 256px;
      overflow-y: scroll;
    }
    h1 {
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-regular;
      color: #000000;
      padding-top: 20px;
    }
    .address-block {
      display: flex;
      padding-bottom: 0;
      .address-checkbox {
        width: 24px;
        .checkbox-checkmark {
          margin-left: 0;
          width: 16px;
          height: 16px;
          left: 2px;
          top: 0;
        }
        .container {
          padding: 0;
          margin: 0;
          .checkbox-checkmark:after {
            width: 7px;
            height: 12px;
            border-radius: 2px;
          }
        }
      }
      .address-details {
        width: 100%;
        padding-bottom: 16px;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
        font-size: $font-size-small;
        line-height: 1.5;
        color: $df-black;
      }
      &:not(:last-child) {
        .address-details {
          width: 85%;
          border-bottom: 2px solid #e3e3e3;
        }
      }
      &:not(:first-child) {
        margin-top: 14px;
      }
      &.list {
        .address-block {
          &:not(:first-child) {
            padding-top: 6px;
            background: #000000;
          }
        }
      }
    }
  }
  .other-shipping-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .other-shipping-heading {
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      font-size: $font-size-regular;
      color: #000000;
    }
    .show-hide-button {
      font-size: 28px;
      font-weight: 100;
      color: #000000;
      cursor: pointer;
      text-align: center;
    }
    .not-show-button {
      pointer-events: none;
    }
  }
  .df-cta-container {
    @include flexbox();
    @include justify-content(space-between);
    @media screen and (max-width: $MOBILE_WIDTH_MAX) {
      @include justify-content(flex-start);
      @include flex-direction(column);
    }
    margin: 32px auto;
    padding: 0 16px;
    white-space: nowrap;
  }
}


