.UserListActions {
  font-size: 0;
  display: block;
  text-align: center;
  margin-bottom: 1rem;

  .df-cta {
    margin: 0 $df-padding-xsmall;
    display: inline-block;
    vertical-align: top;
  }
}
