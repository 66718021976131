/*
  We started out using dx-react-grid and MuiTable only on Admin page,
  but we now use it in user-facing places. So this style applies broadly.

  The styles here are used to match Material UI table with our diamonds table.
*/

$row-height: 50px;

.MuiToolbar-root {
  border-bottom: none !important;
}

.table-admin {
  margin-bottom: 40px;

  .MuiTableCell-root {
    font-family: $font-family-sans-text;
    font-size: $font-size-small;
    border: none;
    padding: 5px 10px;
  }

  thead {
    tr.MuiTableRow-head {
      background-color: $df-light-gray;
      .MuiTableCell-head {
        line-height: $df-padding-large;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: white;
    }
    tbody tr:nth-of-type(even) {
      background-color: $df-light-gray;
    }
  } 
}

.bordered-ar-periods {
  margin-top: $df-padding-medium;
  margin-bottom: $df-padding-medium;

  thead {
    tr {
      background-color: $df-light-gray;
      th {
        font-size: 11px;
        border: none;
      }
    }
  }
  td {
    font-family: $font-family-sans-text;
    font-size: $font-size-small;
    text-align: right;
  }
}
