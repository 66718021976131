.custom-orders-section,
.custom-orders-page {
  p.instructions {
    padding: $df-padding-small;
    margin: 0 auto $df-padding-xlarge;
    max-width: 650px;
    text-align: center;
  }
  button[title='Delete Custom Order'] {
    padding-right: 0;
    margin-right: -2px;
    &:hover *,
    &:focus * {
      fill: $df-red;
    }
  }
  button[title='Approve'],
  button[title='Reject'] {
    margin-left: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    padding: 4px;
    height: auto;
    &:hover,
    &:focus {
      border: 1px solid $df-red;
    }
  }
}
.orders-page .dashboard-header {
  @include flexbox();
  @include flex-direction(column);
  margin: 0 0 $df-padding-large;
  padding: 0 0 $df-padding-large;
  border-bottom: 1px solid #eee;
}
.orders-page .dashboard-header-column {
  @include flexbox();
  @include flex-direction(column);
  text-align: center;
  width: 100%;
  margin: $df-padding-small 0 0;
}
.orders-page .dashboard-header .company-name {
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  font-family: "Helvetica Now Display", "system";
  letter-spacing: 2.75px;
  width: 100%;
  margin: 0;
}

.orders-page .df-title {
  margin: 0;
  padding: 0 0 $df-padding-large 0;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 2.75px;
}
.orders-page table.MuiTable-root {
  margin: 0 0 $df-padding-large;
}

.order-checkout-page {
  margin: 0 0 $df-padding-xlarge 0;

  .order-form-list-container {
    margin: $df-padding-xlarge 0;
  }

  .df-cta-container {
    display: flex;
    justify-content: center;
  }

  .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $df-padding-large;
    margin-top: .5rem;
  }

  .back-link {
    margin: 0;
  }

  .date {
    margin: 6rem auto;
    width: 300px;
    > div {
      width: 100%;
    }
  }
}

