.accept-bg {
  background-color: white;
  margin: 80px 32px;
  .accept-wrapper {
    .invited-user-sign-up_border {
      border-bottom: 1px solid #cccccc;
      margin: 0px 16px;

      .invited-user-sign-up {
        display: flex;
        justify-content: center;
        height: 20px;
        color: #000000;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
    .accept-content {
      padding: 40px 24px 0px;
      text-align: center;
    }
    .wrapper {
      text-align: center;
      .input-wrapper {
        margin: 0 0 24px;
        input {
          padding: 0 8px;
        }
      }
      .df-text-inputs {
        width: 220px !important;
        height: 36px;
        background-color: #ffffff;
        font-size: $font-size-regular;
        border: 0;
        border: 1px solid $df-black;
        &::-webkit-input-placeholder {
          color: $df-black;
        }
      }
    }
    .PasswordInput {
      padding-bottom: 10px;
      border: 1px solid #cccccc;
      width: 220px;
      height: 36px;
      font-size: $font-size-regular;
      text-align: left;
      margin: auto;
      input {
        border: none;
        padding: 3px;
        width: 200px;
        height: 32px;
        &::-webkit-input-placeholder {
          color: $df-black;
        }
      }
      .password-icon {
        bottom: 2px;
      }
    }
    .button-wrapper {
      width: 250px;
      margin: auto;
    }
  }
}

//desktop view
@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .accept-bg {
    height: inherit;
    background-color: #fbfbfb;
    margin: 0px;
    .accept-wrapper {
      background: white;
      margin: 88px auto;
      width: 800px;
      padding: 80px;
      .invited-user-sign-up_border {
        width: 500px;
        margin: auto;
      }
      .invite-user-signup {
        background: white;
        width: 800px;
        height: 424px;
        margin-top: 90px;
        font-size: 18px;
      }

      .wrapper {
        display: flex;
        justify-content: center;
        margin: 24px 0px;
        .input-wrapper {
          margin: 0 24px 24px;
          width: 50%;
        }
      }

      .edit-password {
        width: 220px !important;
        height: 36px;
        background-color: #ffffff;
        font-size: $font-size-regular;
        border: 0;
        border: 1px solid $df-gray;
        &::-webkit-input-placeholder {
          color: $df-black;
        }
      }

      .button-wrapper {
        text-align: center;
      }
      .save {
        width: 250px;
        height: 36px;
        background-color: $df-red;
        border: none;
        margin: 0px 225px 94px 225px;
        margin-bottom: 0px;
      }
    }
  }
}

//tablet view only
@media screen and (min-width: $TABLET_WIDTH_MIN) and (max-width: $TABLET_WIDTH_MAX) {
  .accept-bg {
    background: #fbfbfb;
    height: inherit;
    padding: 80px 32px;
    margin: 0px;
    .accept-wrapper {
      width: 300px;
      background: white;
      height: 460px;
      margin: 24px auto;
      padding: 32px 0px;
    }
    .invited-user-sign-up_border {
      margin: auto;
    }
    .invite-user-signup {
      font-size: 18px;
    }
  }
}
