.UserEditPage {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    padding: 0;
    height: auto;
  }
  @include box-sizing();
  position: relative;
  display: block;
  padding: 0 0 $df-padding-xlarge;
  width: 100%;
}
.UserEditPage .form-wrapper {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    @include flex-direction(column);
    margin: $df-padding-large $df-padding-small;
    padding: 0;
  }
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  padding: $df-padding-medium 0;
  max-width: 100%;
}
.UserEditPage .credit-card-link {
  padding-bottom: $df-padding-large;
  text-align: left;
  display: block;
}

// LEFT COLUMN ADDRESS
.UserEditPage .address-wrapper {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    width: 100%;
  }
  border: 1px solid #eee;
  padding: $df-padding-small;
  text-align: left;
  width: 50%;
}
.UserEditPage .company-name {
  font-size: 16px;
  color: #000;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: $df-padding-small;
}

// BILLING ADDRESS
.UserEditPage .bill-to-address {
  border-bottom: 2px solid #eee;
  display: block;
  margin: 0;
}
.UserEditPage .bill-address-heading {
  background: #eee;
  padding: $df-padding-small;
  display: block;
}
.UserEditPage .bill-address-detail {
  padding: $df-padding-small 35px;
  color: #000;
}

// SHIPPING ADDRESS
.UserEditPage .shipping-address {
  border-bottom: 1px solid #eee;
}
.UserEditPage .shipping-address-heading {
  background: #eee;
  padding: $df-padding-small;
  margin-bottom: -#{$df-padding-medium};
  display: block;
}
.UserEditPage .shipping-address-component {
  display: block;
  padding: 0;
}

// SHIPPING ADDRESS LIST
.UserEditPage .shipping-address-list-head {
  @include flexbox();
  @include justify-content(space-between); 
  @include align-items(center);
  padding: $df-padding-small 0;
  border-bottom: 1px solid #eee;
}
.UserEditPage .shipping-address-list-head .toggle {
  cursor: pointer;
  line-height: 1;
  font-size: 28px;
}
.UserEditPage .shipping-address-list-head .text {
  cursor: pointer;
  font-size: 16px;
}
.UserEditPage .shipping-address-list {
  display: block;
  border-bottom: 1px solid #eee;
}
.UserEditPage .shipping-address-list-body {
  display: block;
  margin-top: -1px;
}
.UserEditPage .address-checkbox {
  display: block;
  margin-top: 4px;
}

// ADDRESS BLOCK
.UserEditPage .address-block {
  @include flexbox();
  padding: $df-padding-small 0 0;
  border-top: 1px solid #eee;
  margin: 0;
}
.UserEditPage .address-block .address-details {
  font-family: $font-family-sans-text;
  padding: 0 $df-padding-small;
  font-size: $font-size-small;
  border: 0 !important;
  font-weight: 200;
  line-height: 1.5;
  width: 100%;
  color: #000;
  height: auto;
  margin: 0;
  padding-bottom: $df-padding-small;
}
.UserEditPage .address-block .dashboard-checkbox {
  display: none;
}
.UserEditPage .address-block .address-checkbox {
  position: relative;
}
.UserEditPage .address-checkbox .container {
  display: block;
  padding: 0;
}

// RIGHT COLUMN USER EMAIL
.UserEditPage .user-info-wrapper {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    margin-top: $df-padding-medium;
    width: 100%;
  }
  border: 1px solid #eee;
  border-left: 0;
  padding: $df-padding-small;
  text-align: left;
  width: 50%;
}
.UserEditPage .user-email-head {
  padding-bottom: $df-padding-small;
  font-size: 16px;
  color: #000;
}
.UserEditPage .user-email-body {
  padding-bottom: $df-padding-small;
  color: #000;
}

// RIGHT COLUMN USER DETAILS
.UserEditPage .user-info-head {
  background: #eee;
  padding: $df-padding-small;
  margin: $df-padding-large 0 0;
  display: block;
}
.UserEditPage .user-info-body {
  display: block;
  position: relative;
}
.UserEditPage .user-info-body .email_info {
  padding-bottom: 20px;
  color: $df-black;
}
.UserEditPage .user-info-body .user-info {
  position: relative;
  padding: 10px 0;
  color: $df-black;
  .info-field {
    border: none;
    width: 100%;
    color: $df-black;
  }
  .edit-info {
    bottom: 30px;
    position: absolute;
    z-index: 1;
    right: 20px;
    span {
      width: 18px;
      height: 18px;
      position: absolute;
      border-radius: 100%;
      background-color: white;
      border: 1px solid $df-gray-footer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .Svg {
      width: 8px;
      height: 8px;
      fill: $df-red;
    }
  }

  label.container {
    min-height: 30px;

    .checkbox-checkmark {
      left: 10px;
      top: 5px;
      margin: 0;
    }
    .checkbox-label {
      position: absolute;
      left: 40px;
      top: 0px;
      padding: 0;
      margin: 0;
      text-align: left;
    }
  }
}
.UserEditPage .user-info-body .hide-edit {
  display: none;
}
.UserEditPage .user-info-body .current-password {
  padding-top: 10px;
  width: 100%;
}
.UserEditPage .user-info-body .submit {
  margin: 30px auto 0px;
  text-align: center;
  width: 100%;

  button {
    max-width: 250px;
  }
}
.UserEditPage .user-info-body input[type="password"],
.UserEditPage .user-info-body input[type="text"] {
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  font-size: 15px;
}
.UserEditPage .user-info-body input.edit-box {
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
}
.UserEditPage .user-info-body .PasswordInput {
  padding: 10px 0;
  &:first-child {
    padding: 0 0 10px;
  }
  .password-icon {
    bottom: 10px;
  }
}

// CHECKBOXES
.UserEditPage input[type=checkbox],
.UserEditPage .checkbox-checkmark {
  position: relative;
  display: block;
  top: auto !important;
  left: auto !important;
  height: 18px !important;
  width: 18px !important;
  margin: 0;  
  border: solid 1px #ccc;
  background-color: #fff;
}
.UserEditPage .checkbox-checkmark:after {
  top: 1px;
  bottom: auto;
  width: 7px;
  height: 12px;
  border-radius: 2px;
}
.UserEditPage input[type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 0;
}
.UserEditPage .dashboard-checkbox .container {
  min-height: 0 !important;
  margin: 0;
  padding: 0;
}
.UserEditPage .checkbox-label {
  color: #000;
  position: absolute;
  top: -2px !important;
  left: 25px !important;
}

// CTA
.UserEditPage .df-cta-container {
  padding: $df-padding-large 0 0;
  margin: 0;
}
.UserEditPage .df-cta-container button {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    width: 100%;
  }
  &:last-child {
    @media screen and (max-width: $MOBILE_WIDTH_MAX) {
      margin-top: $df-padding-medium;
    }
  }
  min-width: 49%;
}
