/*
  WARNING: This page has been heavily customized to meet unanticipated external style demands.
           The code here does not represent 'patterns' that we should follow in the future.
           This code was written quickly out of necessity.
           When we anticipate needing a grid/table component that is this heavily customized, 
           we should build one from scratch, similar to our DiamondList component.
*/

.CustomerAccountsPage {
  .MuiTable-root {
    z-index: 1;
  }
  .MuiTableCell-root {
    font-size: inherit;
    padding: 4px;
  }
  .MuiInput-root {
    margin: 4px 0;
  }

  // The fixed class will be appended when the table scrolls.
  .MuiTableHead-root.fixed {
    position: fixed;
    top: 90px;
    background-color: white;
    max-width: 1200px;
    min-width: 1200px;
  }

  // See CustomerAccountsPage.tableHeaderCell
  // These are off by 8px to account for the 4px padding above.
  th.wide {
    width: 130px;
    min-width: 130px;
  }
  th.normal {
    width: 100px;
    min-width: 100px;
  }
  th.narrow {
    width: 80px;
    min-width: 80px;
  }

  // tablet view and below
  @media screen and (max-width: $TABLET_WIDTH_MAX) {
    .MuiTableHead-root.fixed {
      position: fixed;
      top: 60px;
      background-color: white;
    }
  }

}
