.dashboard-header {
  display: flex;
  margin-bottom: 20px;
  .dashboard-header-column {
    display: block;
    align-items: center;
    font-family: $font-family-sans-text;
    font-weight: $font-weight-regular;
    line-height: 1.14;
    font-size: $font-size-regular;
    width: 50%;
    margin: 55px 25px 0 25px;

    color: $df-black;

    h1 {
      font-size: $font-size-large;
    }

    ul {
      list-style-type: none;
      line-height: 1.5;
      text-align: left;
    }
  }

  .dashboard-header-column-vertical {
    display: flex;
    align-items: center; /* does vertically center */
    justify-content: center; /* does horizontally center */
  }

  .company-name {
    font-family: $font-family-sans-text;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: $font-size-regular;
    color: $df-black;
  }
}
.dashboard-edit {
  height: 1px;
  border-bottom: 1px solid $df-gray-footer;
  position: relative;
  z-index: 1;

  span {
    position: absolute;
    left: calc(50% - 15px);
    /* top: -50%; */
    top: -15px;
    border-radius: 100%;
    border: 1px solid $df-gray-footer;
    background-color: white;
    padding: 2px 6px;
  }

  .Svg {
    height: 15px;
    fill: $df-red;
  }
}

.dashboard-summaries {
  justify-content: space-between;
}

.dashboard-summary {
  display: flex;
  margin: 20px 0;

  .card {
    width: 100%;
    border-radius: 0;
    .card-header,
    .card-footer {
      background-color: white;
    }

    .card-header {
      font-family: $font-family-sans-display;
      font-weight: $font-weight-bold;
      font-size: 16px;
    }

    .card-body {
      .card-title {
        margin-bottom: 5px;
      }
      .current-amount {
        font-size: 25px;
        line-height: 1;
        text-align: center;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
      }

      .dashboard-text {
        text-align: center;
        font-size: $font-size-small;
        color: $df-black;
      }
    }

    .card-footer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 6px 16px;
    }
  }

  .invoice-status {
    display: flex;
    color: $df-black;

    .material-icons {
      margin-right: 10px;
    }
    .check-mark {
      color: white;
      background-color: black;
      border-radius: 20px;
    }
  }
}

@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .dashboard-header {
    .dashboard-header-column {
      margin: 25px;
    }
  }

  .dashboard-summaries {
    display: flex;
    max-width: $layout-width;
    margin: auto;
    margin-top: 32px;

    .dashboard-summary {
      max-width: 49%;
      flex-grow: 1;

      margin-top: 1px;
      margin-left: 1px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .dashboard-header {
    .dashboard-header-column {
      font-size: $font-size-small;
      margin: 20px 10px 0 10px;
    }
  }
}
