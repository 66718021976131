$navHeight: 60px;
$navPadding: 20px;
$navBlack: #333333;

#root {
  transition: margin-left 0.5s;
  width: 100%;
  min-height: 100vh;
}

.Nav_wrapper {
  min-height: $navHeight;
  background: #fff;
  font-size: $font-size-regular;

  .Sidebar__overlay {
    @include transition(opacity 0.5s, left 0.5s);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    z-index: 0;
    display: block;

    &.active {
      left: -250px;
      display: block;
      opacity: 0.7;
      z-index: 100;
    }
  }
}


.Nav {
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  height: $navHeight;
  position: fixed;
  background: #fff;
  width: 100vw;
  z-index: 10;

  // This is used in a sidenav
  .Navbar__logo {
    width: 250px;

    .st0 {
      stroke: black;
      fill: #231f20;
    }
  }

  .Navbar__logo__mobile {
    height: 30px;
    width: 30px;
  }

  .dfLogo {
    width: 300px;
    cursor: pointer;
  }

  .Navbar {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $df-gray-nav;
    padding: 16px 20px;

    svg {
      stroke: black;
    }

    .Navbar__icons {
      display: flex;
      align-items: center;
      img {
        height: 20px;
      }

      #cart-count {
        background: $df-red;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
        color: $df-white;
        font-size: $font-size-small;
        border-radius: 50%;
        line-height: 20px;
        min-width: 20px;
        text-align: center;
        margin: 2px;
      }

      .Svg,
      span {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  #Desktopnav {
    display: flex;
    margin: 0;
    margin-top: 3px;
    justify-content: center;
    .nav-link {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: fit-content;
      max-width: 174px;
      margin: -45px 22px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      border-bottom: 2px solid transparent;
      white-space: nowrap;
      text-transform: uppercase;
      a {
        color: $df-black;
        font-family: $font-family-sans-text;
        font-weight: $font-weight-regular;
        font-size: $font-size-small;
        height: 20px;
        &.active {
          color: $df-red;
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $df-red;
          }
        }
      }
    }
  }

  #Sidenav {
    display: flex;
    flex-direction: column;
    width: 0;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: white;

    &.active {
      width: 250px;
      margin-left: -250px;
    }

    .side-nav-header {
      display: flex;
      justify-content: space-between;
      width: 250px;
    }

    .nav-link {
      display: flex;
      white-space: nowrap;
      align-items: center;
      height: $navHeight;
      padding: 20px;
      border-bottom: 2px solid $df-gray-nav;
      text-decoration: none;
      color: $df-gray-navlink;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      transition: 0.3s;

      .Svg {
        fill: $df-red;
        stroke: $df-red;
        margin-right: 18px;
      }

      .close-side-nav {
        cursor: pointer;
        width: 16px;
      }

      a {
        color: black;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .help {
      margin: 22px;
      a {
        display: flex;
        flex-direction: column;
        color: $df-black;
        .underline {
          text-decoration: underline;
        }
        span {
          white-space: nowrap;
        }
      }
    }

    .nav-link.children {
      padding-left: 1.5rem;
    }

    .admin_link {
      position: relative;
      height: 100%;
      white-space: nowrap;

      a {
        position: absolute;
        bottom: 10px;
        left: 10px;
      }

    }
  }

  #Minicart__container {
    display: none;
  }

  #NavSearch__container {
    position: absolute;
    background-color: $df-gray-nav;
    z-index: 1;
    width: 0%;
    height: 100%;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    .NavSearch {
      display: flex;
      opacity: 1;
      justify-content: space-between;
      align-items: center;
      background-color: $navBlack;
      height: $navHeight;
      .NavSearch__bar {
        display: flex;
        .df-text-inputs {
          background: transparent;
          border: 0;
        }
      }
    }
  }

  .Svg {
    height: 25px;
  }

  .Svg.search {
    //BP-107: hide until post mvp
    display: none;

    height: 30px;
  }

  .Svg.cart {
    height: 30px;
  }
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
}

@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .Nav_wrapper {
    min-height: 90px;
    background: #fff;
    &.Nav_wrapper--thin {
      min-height: 60px;
      .Nav {
        height: 60px;
        .Navbar {
          padding: 20px 100px;
        }
      }
    }
  }
  .Nav {
    height: 90px;

    .Navbar .Navbar__icons #cart-count {
      top: $df-padding-large;
      right: 160px;
    }
    .Navbar {
      padding: 34px 100px;

      .Navbar_menu {
        width: 100%;
      }

      .Navbar__icons {
        justify-content: flex-end;
        min-width: 200px;
        text-align: right;
        float: right;
      }
    }

    .Minicart__container__close {
      visibility: hidden !important;
      opacity: 0 !important;
    }

    .Minicart__container__open {
      visibility: visible !important;
      opacity: 1 !important;
      transition-duration: 0.5s;
    }

    /* The max height of the mini-cart. */
    $miniCartMaxHeight: 100vh;
    #Minicart__container {
      /* max-height required for transition. */
      max-height: $miniCartMaxHeight;
      transition: all 0.5s ease;

      display: block;
      position: absolute;
      background-color: $df-black;

      width: 320px;
      right: 100px;
      z-index: 1;
      overflow: hidden;
      .close__container {
        margin: 15px 20px 0 20px;
        text-align: right;
        .close-minicart {
          height: 20px;
          fill: white;
          stroke: white;
        }
      }

      .minicart-list {
        max-width: 500px;
        max-height: calc(#{$miniCartMaxHeight} - 350px);
        margin: auto;
        overflow-y: auto;
        .empty-cart {
          color: white;
          text-align: center;
          margin-bottom: 39px;
        }
      }
      .total-price-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
      }
      .total-price {
        display: flex;
        justify-content: space-between;
        margin: 15px;
        color: white;

        .label {
          margin-right: 5px;
        }
      }

      .df-cta {
        width: 250px;
        margin: 15px auto 0 auto;
      }
    }
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .Nav .Navbar .Navbar__icons .Svg,
  .Nav .Navbar .Navbar__icons span {
    margin-right: 4px;
  }

  .Nav {
    .dfLogo {
      width: 200px;
    }
  }
}
