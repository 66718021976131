.recurring-order-discount-table {
  padding: $df-padding-small 0px;

  .row {
    margin: 0;

    .col {
      padding: 0;
    }
  }
  .col.header {
    min-width: 130px;
    font-family: 'Helvetica Now Display', 'system';
    font-weight: 700;
    text-align: left;
  }
  .row.duration {
    .col {
      border-bottom: 1px solid $df-gray;
    }
  }
}

// tablet view and below
@media screen and (max-width: $TABLET_WIDTH_MAX) {
  .recurring-order-discount-table {
    max-width: 640px;
    margin: 0 auto;
  }
}