.checkout-page {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    padding: 0;
    height: auto;
  }
  @include box-sizing();
  position: relative;
  display: block;
  padding: 0 0 $df-padding-xlarge;
  width: 100%;
}
.checkout-page .main-wrapper {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    @include flex-direction(column);
    margin: $df-padding-large $df-padding-small;
    padding: 0;
  }
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  padding: $df-padding-medium 0;
}

// LEFT COLUMN ADDRESS
.checkout-page .column-left {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    width: 100%;
  }
  border: 1px solid #eee;
  padding: $df-padding-small;
  text-align: left;
  width: 50%;
}

// BILLING ADDRESS
.checkout-page .bill-to-address {
  border-bottom: 2px solid #eee;
  display: block;
  margin: 0;
}
.checkout-page .bill-address-heading {
  background: #eee;
  padding: $df-padding-small;
  display: block;
}
.checkout-page .bill-address-detail {
  padding: $df-padding-small 35px;
  color: #000;
}

// SHIPPING ADDRESS
.checkout-page .shipping-address {
  border-bottom: 1px solid #eee;
}
.checkout-page .shipping-address-heading {
  background: #eee;
  padding: $df-padding-small;
  margin-bottom: -#{$df-padding-medium};
  display: block;
}
.checkout-page .shipping-address-component {
  display: block;
  padding: 0;
}

// SHIPPING ADDRESS LIST
.checkout-page .shipping-address-list-head {
  @include flexbox();
  @include justify-content(space-between); 
  @include align-items(center);
  padding: $df-padding-small 0;
  border-bottom: 1px solid #eee;
}
.checkout-page .shipping-address-list-head .toggle {
  cursor: pointer;
  line-height: 1;
  font-size: 28px;
}
.checkout-page .shipping-address-list-head .text {
  cursor: pointer;
  font-size: 16px;
}
.checkout-page .shipping-address-list {
  display: block;
  border-bottom: 1px solid #eee;
}
.checkout-page .shipping-address-list-body {
  display: block;
  margin-top: -1px;
}
.checkout-page .address-checkbox {
  display: block;
  margin-top: 4px;
}

// ADDRESS BLOCK
.checkout-page .address-block {
  @include flexbox();
  padding: $df-padding-small 0 0;
  border-top: 1px solid #eee;
  margin: 0;
}
.checkout-page .address-block .address-details {
  font-family: $font-family-sans-text;
  padding: 0 $df-padding-small;
  font-size: $font-size-small;
  border: 0 !important;
  font-weight: 200;
  line-height: 1.5;
  width: 100%;
  color: #000;
  height: auto;
  margin: 0;
  padding-bottom: $df-padding-small;
}
.checkout-page .address-block .dashboard-checkbox {
  display: none;
}
.checkout-page .address-block .address-checkbox {
  position: relative;
}
.checkout-page .address-checkbox .container {
  display: block;
  padding: 0;
}

// RIGHT COLUMN
.checkout-page .column-right {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    border-left: 1px solid #eee;
    margin-top: $df-padding-medium;
    width: 100%;
  }
  @include flexbox();
  @include flex-direction(column);
  border: 1px solid #eee;
  border-left: 0;
  padding: $df-padding-small;
  text-align: left;
  width: 50%;
}
.checkout-page .cart-heading {
  @include flexbox();
  @include justify-content(space-between);
  padding: $df-padding-small;
  background: #eee;
}
.checkout-page .CartListItem {
  padding: 0 0 $df-padding-medium;
  border-bottom: 1px solid #eee;
  max-width: none;
}
.checkout-page .CartListItem .diamond-info-left ul {
  padding: 0 0 0 $df-padding-medium;
}
.checkout-page .CartListItem .diamond-price {
  padding-right: $df-padding-small;
}
.checkout-page .total-price {
  @include flexbox();
  @include justify-content(space-between);
  padding: $df-padding-small;
  background: #eee;
}
.checkout-page .comment-box {
  padding: 0;
  margin: $df-padding-medium 0 0;
  max-width: none;
}
.checkout-page .label-comment {
  @include justify-content(flex-start);
  margin: 0;
  text-align: left;
}

// CHECKBOXES
.checkout-page input[type=checkbox],
.checkout-page .checkbox-checkmark {
  position: relative;
  display: block;
  top: auto !important;
  left: auto !important;
  height: 18px !important;
  width: 18px !important;
  margin: 0;  
  border: solid 1px #ccc;
  background-color: #fff;
}
.checkout-page .checkbox-checkmark:after {
  top: 1px;
  bottom: auto;
  width: 7px;
  height: 12px;
  border-radius: 2px;
}
.checkout-page input[type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 0;
}
.checkout-page .dashboard-checkbox .container {
  min-height: 0 !important;
  margin: 0;
  padding: 0;
}
.checkout-page .checkbox-label {
  color: #000;
  position: absolute;
  top: -2px !important;
  left: 25px !important;
}

// CTA
.checkout-page .df-cta-container {
  @include justify-content(flex-end);
  @include align-items(flex-end);
  @include flex-direction(column);
  @include flex-grow(1);
  padding: $df-padding-large 0 0;
  margin: 0;
}
.checkout-page .df-cta-container .payment_method {
  padding: 0 $df-padding-small 0 0;
}
.checkout-page .payment-cc-info {
  margin: $df-padding-small 0 0;
}
.checkout-page .checkout-error {
  margin: $df-padding-small/2 0 0;
}
.checkout-page .df-cta-container .checkout-button,
.checkout-page .df-cta-container button {
  @media screen and (max-width: $MOBILE_WIDTH_MAX) {
    width: 100%;
  }
  margin: $df-padding-small 0 0;
  min-width: 50%;
}

// EMPTY CART
.checkout-page .empty-cart {
  padding: $df-padding-small;
}
.checkout-page .empty-cart .df-link {
  padding-left: $df-padding-small;
}
