.NotifyUsersPage {
  .row {
    margin-top: $df-padding-medium;
  }

  .container {
    padding: 0;

    .checkbox-checkmark {
      display: inline-block;
      position: relative;
      margin: 0 $df-padding-small 0 0;
    }
    .checkbox-label {
      display: inline-block;
      position: relative;
    }
  }

  .warning {
    margin: $df-padding-small;
    font-size: $font-size-x-small;
  }
}

// This is the email preview. This is copied from the Mandrill template.
// See https://mandrillapp.com/templates/code?id=blank-notification-template
.BlankNotificationTemplate {
  .table-name {
      text-align: right;
  }
  .table-value {
      text-align: left;
  }
  .bodyContent{
    /* width: 600px; */
    border: 1px solid #BCB5B9;
    text-align: center;
    margin-top: 30px;
  }
  .diamond__images{
    justify-content: center;
    margin-top: 6px;
  }
  .image{
    padding-right: 60px;
  }
  .sub-heading{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    color: #333333;
    margin-top: 32px;
    margin-bottom: 0;
    font-family: sans-serif !important;
  }
  .logo--image{
    width: 300px;
  }
    .df__link{
        color: #ff3c00 !important;
        text-decoration: underline;
        cursor: pointer;
  }
  .df__link:visited {
      color: #ff3c00;
  }        
  .mailContent{
    margin: 40px 50px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 1.25;
    color: #333333;
    font-family: sans-serif !important;
  }
  .btn-view{
    margin-bottom:70px;
    margin-top: 60px;
  }   
  .btn-link{
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 1px;
    background: #ff3c00;
    margin-bottom: 50px;
    border: none;
    padding: 15px 100px;
    font-family: sans-serif !important;
  }
  .btn-link:visited {
      color: #ff3c00;
  }        
  .email-footer{
    background-color: #E4E4E4;
    text-align: center;
    /* width: 600px; */
    margin-bottom: 0px;
    border-bottom: 1px solid #e4e4e4;

    padding-top: $df-padding-medium;
  }
  .copyright {
    margin-bottom: 20px;
    text-align: center;
    font-size: 12px;
    color: #333333;
    font-weight: 300;
    font-family: sans-serif !important;
  }
  .footer__table{
    padding: 20px 46px 20px 46px;
    background-color: #E4E4E4;
    text-align: center;
    width: 600px;
  }
  .footer--table--content{
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 300;
  }
  .remove--underline{
    text-decoration: none;
        color: #333333 !important;
        cursor: pointer;
  }
  .body__logo{
    border-bottom: 1px solid #CCCCCC;
    padding: 20px;
  }
  @media screen and (max-width: 599px){
    .bodyContent{
        width: 320px;
        margin: auto;
    }
    .logo--image {
        width: 206px;
    }
    .body__logo{
        padding: 25px;
    }
    .footer {
        width: 320px;
    }
    .sub-heading{
        margin-left: 45px;
        margin-right: 42px;
        margin-bottom: 22px;
    }
    .image{
        padding-right: 50px;
    }
    .diamond__images{
        padding: 0 16px;
    }
    .mailContent{
        margin: 40px 20px;
    }
    .btn-link{
        padding: 20px 40px;
    }
    .footer--content{
        display: inline-grid;
    }
    .footer--content>td{
        padding: 8px;
    }
    .footer__table {
        width: 320px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .copyright {
        margin-bottom: 15px;
    }
    .btn-view {
        margin-top: 70px;
    }
    .df__link{
        color: #ff3c00 !important;
    }
    .df__link:visited {
        color: #ff3c00;
    }
  }
}
