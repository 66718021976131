.footer-pages {
  margin: 0 12px;
  color: $df-black;
  .df-title {
    font-size: 16px;
    font-weight: $font-weight-light;
    margin-top: 89px;
    margin-bottom: 39px;
  }
  .df-title-underline {
    font-family: $font-family-sans-display;
    font-weight: $font-weight-light;
    font-size: $font-size-regular;
    height: 26px;
    border-bottom: 1px solid #e7e7e7;
    line-height: 1.25;
    color: #000000;
    @media screen and (min-width: $DESKTOP_WIDTH_MIN) {
      margin: 0px 50px 30px 50px;
    }
  }
  .digital {
    font-family: $font-family-sans-display;
    font-size: $font-size-regular;
    font-weight: $font-weight-light;
  }
  .df-page-body {
    margin-bottom: 100px;
    font-size: $font-size-regular;
    font-family: $font-family-sans-text;
    font-size: $font-size-regular;
    color: $df-black;
  }
  .text-align-center {
    text-align: center;
  }
  .df-list {
    // list-style: disc;
    padding-left: 20px;
    margin-bottom: 25px;
    li {
      margin-bottom: 10px;
    }
    strong {
      font-family: $font-family-sans-display;
      font-weight: $font-weight-bold;
    }
  }
  iframe {
    width: 100%;
  }
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .footer-pages {
    .df-title {
      margin-top: 62px;
      margin-bottom: 39px;
      font-size: 16px;

      .digital {
        margin-top: -21px !important;
        margin-bottom: -8px !important;
      }
    }
    .df-page-body {
      max-width: 621px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .footer-pages {
    .df-page-body {
      max-width: 1015px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
