.CreditCardPage {
  h1 {
    margin-top: $df-padding-large;
  }
  
  .submit {
    margin-top: $df-padding-medium;
    text-align: center;
  }
}
