.CartListItem {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  font-family: $font-family-sans-text;
  font-weight: $font-weight-regular;
  border-bottom: 2px solid $df-gray;
  padding-bottom: $df-padding-small;

  // Let the width auto scale when an image is present
  img.diamond-icon {
    height: 42px;
    width: 42px;
  }

  div.diamond-icon-container {
    min-width: 42px;
    margin-top: 5px;
  }

  .material-icons.delete {
    color: $df-black;
  }

  .diamond-info-left {
    display: flex;
    flex: 1;

    ul {
      list-style-type: none;
      padding-left: 10px;
      color: $df-black;
      width: 100%;

      .diamond-sku {
        font-size: 11px;
      }
    }
  }

  li {
    font-size: $font-size-x-small;

    span.shape {
      text-transform: capitalize;
      font-size: $font-size-medium;
      display: inline-block;
      width: 100%;
    }
    span.fieldName {
      display: inline-block;
      min-width: 110px;
    }
  }

  .diamond-info-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;

    .diamond-certificate {
      display: flex;
      align-items: center;
      font-size: 11px;
    }

    .diamond-price {
      font-size: $font-size-regular;
      font-family: $font-family-sans-display;
      font-weight: $font-weight-bold;
    }

    .delete {
      height: 22px;
      fill: $df-black;
      &:hover {
        fill: $df-red;
        cursor: pointer;
      }
    }
  }
}

// Custom styles for the cart that drops down when you mouse-over your bag
.minicart-list {
  .CartListItem {
    ul {
      li{
        color: white;

        span.shape {
          font-size: $font-size-regular;
        }
        span.fieldName {
          min-width: 0;
          color: $df-light-gray;
        }
        svg.delete {
          fill: $df-light-gray;
        }
      }
    }
  }
}

@media screen and (min-width: $TABLET_WIDTH_MIN) {
  .CartListItem {
    margin: 15px auto;
    max-width: 500px;
  }
}

//desktop overlay
@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .minicart-list {
    .CartListItem {
      margin: 15px;
      
      .diamond-icon {
        filter: invert(1);
      }

      .diamond-info-right {
        .diamond-price {
          color: white;
        }
      }
    }
  }
}
