.select-company-modal {
  min-height: 358px;
}

.admin-submit-btn-container {
  margin: 20px auto;
}

.MuiDialog-root,
.MuiInputBase-root {
  .MuiTypography-body1,
  .MuiInputBase-input {
    font-family: $font-family-sans-text;
    font-size: $font-size-small;
  }
}

.admin-select {
  display: block;
  width: 100%;
}
