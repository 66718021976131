.MinicartListItem {
  margin: 10px 10px 0 10px;
  border-bottom: 1px solid $df-gray-dark;
  section {
    display: flex;
    .diamond-icon {
      margin-top: 4px;
      margin-right: 10px;
      width: 42px;
      height: 42px;
      filter: invert(1);
    }

    .Svg {
      height: 19px;
      width: 42px;
      margin-top: 4px;
      margin-right: 10px;
    }
    ul {
      font-size: 14px;
      .title {
        color: white;
      }

      li {
        color: $df-black;

        span {
          margin-right: 5px;
        }
      }
    }
  }
}
