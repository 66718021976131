footer {
  width: 100%;
  position: relative;
}

// Footer
.footer {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-small;

  border-top: 1px solid $df-black;

  max-width: $layout-width; // same size as .main-wrapper
  padding: $df-padding-medium $df-padding-large $df-padding-small $df-padding-large;
  margin: 0 auto;

  .carbon-neutral-logo {
    background-image: url('../static/images/carbon-neutral-logo.png');
    @media screen and (min-width: $TABLET_WIDTH_MIN) {
      background-image: url('../static/images/carbon-neutral-logo-dark.png');
    }
  }

  .footer-col {
    display: flex;
    justify-content: center;
    @media screen and (min-width: $TABLET_WIDTH_MIN) {
      justify-content: inherit;
    }
  }

  .footer-links {
    margin-bottom: 0;
    .footer-link {
      display: block;
      @media screen and (min-width: $TABLET_WIDTH_MIN) {
        display: inline;
      }
      padding-right: 10px;
      padding-bottom: 10px;
      @media screen and (min-width: $TABLET_WIDTH_MIN) {
        padding-right: 15%;
      }

      a {
        color: $df-white;
        text-transform: uppercase;
        @media screen and (min-width: $TABLET_WIDTH_MIN) {
          color: $df-black;
        }
      }
    }
  }

  &.footer-index-page {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    color: $df-white;
    border-top: 1px solid $df-white;

    .footer-link {
      a {
        color: $df-white;
      }
    }
    .carbon-neutral-logo {
      background-image: url('../static/images/carbon-neutral-logo.png');
    }
  }
  
}

@media screen and (max-width: $TABLET_WIDTH_MAX) {
  .footer {
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    margin: 25px $df-padding-large 5px $df-padding-large;
    color: $df-black;
  }
}

@media screen and (max-width: $MOBILE_WIDTH_MAX) {
  .footer {
    font-size: $font-size-xx-small;
    width: 100%;
    color: $df-white;
    background-color: $df-black;
    margin: 0 auto;
    padding: $df-padding-medium;

    &.footer-index-page {
      width: 100%;
    }
  }
}

.carbon-neutral-logo {
  display: block;
  width: 55px;
  height: 59px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../static/images/carbon-neutral-logo.png');
}

.footer__content {
  background-color: $df-gray-footer;
  width: 100%;
  .footer__links {
    font-family: $font-family-sans-text;
    font-weight: $font-weight-regular;
    font-size: $font-size-regular;
    text-align: center;
    margin-top: 49px;
    margin-bottom: 0;
    line-height: 2.14;
    letter-spacing: 0.5px;
    .footer__link {
      a {
        color: $df-black;
      }
    }

    .footer__link.active {
      a {
        color: $df-red;
      }
    }
  }

  .footer__copyright {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-top: 51px;
    margin-bottom: 26px;
    color: $df-gray-copyright;
    padding-top: 2px;
  }
}
@media screen and (max-width: $TABLET_WIDTH_MAX) {
  .footer__container {
    display: grid;
  }
}
@media screen and (min-width: $DESKTOP_WIDTH_MIN) {
  .footer__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 10px 20px;
    .footer__links {
      display: flex;
      justify-content: space-between;
      margin: 0;
      align-items: center;
      width: 666px;
      li.footer__link {
        max-height: 20px;
        display: flex;
        align-items: center;
      }
      .footer__link {
        a {
          letter-spacing: 0.88px;
          height: 26px;
        }
      }
    }
    .footer__copyright {
      margin: 0;
      font-size: $font-size-regular;
      letter-spacing: 0.88px;
      font-family: $font-family-sans-text;
      font-weight: $font-weight-regular;
      height: 20px;
    }
  }
}
